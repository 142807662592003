<template>
  <div>
    <Header/>
    <PreguntasFrecuentes />
    <Drawer />
    <Footer/>
  </div>
</template>

<script>
import PreguntasFrecuentes from "./PreguntasFrecuentes.vue";
import Drawer from "../drawer/Drawer.vue";
import Header from "../commons/Header.vue";
import Footer from "../commons/Footer.vue";

export default {
  name: "Index",
  components: {
    PreguntasFrecuentes,
    Drawer,
    Header,
    Footer
},
  data() {
    return {};
  },
  methods: {
 
  },
};
</script>

<style>
</style>