<template>
  <main style="background-color: #f7b53f; min-height: 100vh; position: relative; padding-top: 20px;">
    <Header />
    <h2 class="text-center" style="margin-top: 100px;">Resultados Sorteo de Cámaras</h2>
    <p class="text-center" style="color: #FFF;">Ganadores de las primeras 20 cámaras. 11/28/2023</p>
    <img style="margin: 0 auto; display:flex; width: 200px;" src="../../assets/soscam.png" alt="Cámara">

    <v-simple-table class="elevation-4" style="max-width: 600px; margin: 0 auto; margin-top: 12px; margin-bottom: 100px; background-color: #f7b53f;">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Nombre</th>
            <th class="text-left">Teléfono</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in winners" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.phone }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

                  <div class="mx-auto logos" style="max-width: 400px; padding: 20px; padding-top: 0;">
            <div style="display:flex;gap: 20px; align-items:center;padding: 0; flex-wrap:wrap;">
          <v-img
            :src="require('../../assets/logo-valcar.svg')"
            class="my-3"
            contain
            style="width:10%"
          />
          <v-img
            :src="require('../../assets/logo-arica.png')"
            class="my-3"
            contain
            style="width:5%"
          />
          <v-img
            :src="require('../../assets/logo-core.png')"
            class="my-3"
            contain
            style="width:25%"
          />
            </div>
        </div>
    <p style="text-align: center; font-size: 22px;">Nos comunicaremos por email o whatsapp con los ganadores para coordinar la entrega de las cámaras.</p>
    <Footer />
  </main>
</template>

<script>
import Header from '../commons/Header.vue'
import Footer from '../commons/Footer.vue'

export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
            winners: [
                {
                name: 'Mario Araya',
                phone: 'XXXXXX472'
                },
                {
                    name: 'Paola Rosa Rojas Méndez',
                    phone: 'XXXXXX818'
                },
                {
                    name: 'Zoraida González',
                    phone: 'XXXXXX201'
                },
                {
                    name: 'Jose Quintana',
                    phone: 'XXXXXX971'
                },
                {
                    name: 'Alejandra Karina Santander Minio',
                    phone: 'XXXXXX183'
                },
                {
                    name: 'Moises Rojas',
                    phone: 'XXXXXX365'
                },
                {
                    name: 'Bernardo Antonio Ponce Sanchez',
                    phone: 'XXXXXX001'
                },
                {
                    name: 'Ximena Cisternas',
                    phone: 'XXXXXX191'
                },
                {
                    name: 'Oscar Villca',
                    phone: 'XXXXXX615'
                },
                {
                    name: 'Maria Valdebenito',
                    phone: 'XXXXXX058'
                },
                {
                    name: 'Adolfo Bernales',
                    phone: 'XXXXXX992'
                },
                {
                    name: 'Nelson Gonzalez',
                    phone: 'XXXXXX582'
                },
                {
                    name: 'Elizabeth Farias Clavero',
                    phone: 'XXXXXX674'
                },
                {
                    name: 'Daniel Lopez Vargas',
                    phone: 'XXXXXX093'
                },
                {
                    name: 'Roxana Villar',
                    phone: 'XXXXXX082'
                },
                {
                    name: 'Ivonne Vargas Medel',
                    phone: 'XXXXXX660'
                },
                {
                    name: 'Bárbara Calvo Ruiz',
                    phone: 'XXXXXX474'
                },
                {
                    name: 'Anibal Alejandro Cofré Morales',
                    phone: 'XXXXXX113'
                },
                {
                    name: 'Gabriel Antonio Castillo Encina',
                    phone: 'XXXXXX997'
                },
                {
                    name: 'Monserrat Sagre',
                    phone: 'XXXXXX756'
                },
        ]
    }
    }
};
</script>

<style lang="scss" scoped>

		
		
		
		
		
		
</style>
