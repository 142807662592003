<template>
<main style="background-color: #F7B53F; min-height: 100vh; position:relative;">
    <section class="Start2FA">
        <v-container v-if="!codeSent">
            <h3>Elige cómo recibir el código de verificación</h3>
            <v-row>
                <v-col cols="12">
                    <v-expansion-panels multiple>
                        <v-expansion-panel v-for="(phone, i) in maskedUserPhones" :key="i">
                            <v-expansion-panel-header>{{phone.phone}}</v-expansion-panel-header>
                            <div @click="type = 1; phone_id = phone.id" style="cursor: pointer;" :style="[type == 1 ? {backgroundColor: '#F7F1F1'} : null]">
                                <v-expansion-panel-content style="height: 60px;display:flex; align-items:center;"><v-icon class="mr-2">mdi-message-outline</v-icon>Enviar SMS</v-expansion-panel-content>
                            </div>
                            <div @click="type = 2; phone_id = phone.id" style="cursor: pointer;" :style="[type == 2 ? {backgroundColor: '#F7F1F1'} : null]">
                                <v-expansion-panel-content style="height: 60px;display:flex; align-items:center;"><v-icon class="mr-2">mdi-whatsapp</v-icon>Enviar Whatsapp</v-expansion-panel-content>
                            </div>
                            </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>

            </v-row>
            <div style="display:flex; margin:0 auto; width: 100%; max-width: 600px; margin-top: 20px;">
                <v-btn block :loading="loadingbtn" :disabled="loadingbtn || !type" @click="requestAuthCode" color="primary">Siguiente</v-btn>
            </div>
        </v-container>

    <v-form v-else ref="form2" v-model="valid2" lazy-validation>
        <v-container>
        <h3>Ingresa tu código de verificación</h3>
        <p>Recibiste un código de 6 números por {{validationType}}</p>
            <v-row>
                <v-col cols="12">
                    <v-text-field placeholder="123456" maxlength="6" :counter="6" :rules="requiredCode" v-model="code" label="Código"></v-text-field>
                </v-col>
            </v-row>
            <div style="display:flex; margin:0 auto; width: 100%; max-width: 600px; margin-top: 20px;">
                <v-btn block :loading="loadingbtn" :disabled="!disableCodeBtn || loadingbtn" @click="verify" color="primary">Guardar</v-btn>
            </div>
            <div style="display:flex; margin:0 auto; width: 100%; max-width: 600px; margin-top: 20px;">
                <v-btn block :loading="loadingbtn"  @click="codeSent = false" color="primary" text>Volver</v-btn>
            </div>
        </v-container>
    </v-form>

    </section>
    </main>
</template>

<script>
import {requestTwoFactorAuthCode, confirmTwoFACodeLogin} from '../../../helpers/api/sosdenuncias/sosdenuncias'
import { mapState } from 'vuex'

export default {
    data () {
        return {
        phones: [],
        code:'',
        type: null,
        phone_id: null,
        valid: true,
        valid2: true,
        requiredCode: [
            v => !!v || 'Este campo es obligatorio',
            v => ( !!v && /^\d+$/.test(v) ) || 'Ingrese sólo números',
            v => ( !!v && /^.{6}$/.test(v) ) || 'El campo debe tener 6 números',
        ],
        loadingbtn: false,
        codeSent: false,
        message: ''
        }
    },
    computed: {
        ...mapState(['loginData', 'maskedUserPhones']),
        validationType() {
            const types = {
                1:"SMS",
                2:"Whatsapp"
            }
            return types[+this.type]
        },
        disableCodeBtn() {
            if (!this.code || this.code.length !== 6 || /^\d+$/.test(this.code)) {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        requestAuthCode() {
            const data = {
                email: this.loginData.email,
                password: this.loginData.password,
                phone_id: this.phone_id,
                type: this.type
            }

            this.loadingbtn = true;
            requestTwoFactorAuthCode(data).then(res => {
            this.loadingbtn = false;
            this.codeSent = true;
          }).catch(function (error) {
            this.loadingbtn = false;
            this.message = res.message || 'Error'
            this.codeSent = false;
            });
        },
        verify() {
            const data = {
                email: this.loginData.email,
                password: this.loginData.password,
                code: this.code
            }
            if(this.$refs.form2.validate()){
            this.loadingbtn = true;
            confirmTwoFACodeLogin(data).then(res => {
            this.loadingbtn = false;
            if (res.code == 200) {
                this.$router.push('/')
            }
        }).catch(function (error) {
            this.loadingbtn = false;
            this.message = res.message || 'Error'
            });
            }
        },
    },
    mounted() {
        if (!this.loginData || !this.maskedUserPhones) {
            this.$router.push('/login')
        }
    },
}
</script>

<style lang="scss">
.Start2FA {
    border-radius: 15px;
    padding: 20px;
    background-color: #F7F1F1;
    max-width: 800px;
    position: absolute;
    top: 50%;
    left: 50%;
     transform: translate(-50%, -50%);
}
.v-expansion-panel-content__wrap  {
    padding: 0 24px 0px;
    }
</style>