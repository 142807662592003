<template>
<div>
    <section class="Security">
        <v-list>
            <li style="display: flex; justify-content:space-between; align-items:center; width:100%;padding:20px;">
                <div class="d-flex">
                <p style="margin-bottom:0; font-size:16px; font-weight:500;">Cerrar Sesión en todos los Dispositivos</p>
                    <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-2" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span style="width: 300px !important;">Se revocarán los accesos en todos los dispositivos activos</span>
                </v-tooltip>    
                </div>
                <v-btn :loading="loading" :disabled="loading" style="display:flex;" @click="revokeAccess">Seleccionar</v-btn>
            </li>
                <v-divider></v-divider>
            <li style="display: flex; justify-content:space-between; align-items:center; width:100%;padding:20px;">
                <div class="d-flex">
                <p style="margin-bottom:0; font-size:16px; font-weight:500;">Cambiar Contraseña</p>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-2" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span style="width: 300px !important;">Se enviará un correo para restablecer contraseña</span>
                </v-tooltip>   
                </div>
                <v-btn :loading="loading1" :disabled="loading1" style="display:flex;" @click="resetPass">Seleccionar</v-btn>
            </li>
                <v-divider></v-divider>
            <li style="display: flex; justify-content:space-between; align-items:center; width:100%;padding:20px;">
                <div class="d-flex">
                <p style="margin-bottom:0; font-size:16px; font-weight:500;">Restablecer Cuenta</p>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-2" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span style="width: 300px !important;">Se cerrará la sesión en todos los dispositivos y se enviará un correo para cambiar contraseña</span>
                </v-tooltip>   
                </div>
                <v-btn :loading="loading2" :disabled="loading1" style="display:flex;" @click="resetAccount">Seleccionar</v-btn>
            </li>
                <v-divider></v-divider>
        </v-list>
            
    </section>

    <v-alert
    v-if="alert"
    :color="success = true ? 'green' : 'red'"
    :type="success = true ? 'success' : 'error'"
    style="display:flex; position: absolute;top: 20px;right: 5%;"
    transition="slide-y-transition"
    >{{message}}</v-alert>
    </div>
</template>

<script>
import { revokeAllAccess, passwordResetRequest, accountResetRequest  } from '../../../helpers/api/sosdenuncias/sosdenuncias'
export default {
    props: {
        userInfo: {
            type: Object,
        },
    },
    data () {
        return {
            loading: false,
            loading1: false,
            loading2: false,
            alert: false,
            success: false,
            error: false,
            message: ''
        }
    },
    methods: {
        revokeAccess() {
            this.loading = true;
            revokeAllAccess().then(res => {
            this.message = 'Sesión cerrada en todos los Dispositivos'
            this.alert = true;
            this.success = true;
            this.loading = false;
            setTimeout(() => {
                this.alert = false
                this.$router.push('/login')
            }, 3000);
            }).catch(e => {
                this.loading = false;
                this.success = false;
                this.message = 'Error'
            })
        },
        resetPass() {
            this.loading1 = true;
            passwordResetRequest({email: this.userInfo.email}).then(res => {
                this.message = 'Correo de Confirmación enviado'
                this.alert = true;
                this.success = true;
                this.loading1 = false;
                setTimeout(() => {
                    this.alert = false
                }, 3000);
                }).catch(e => {
                    this.loading1 = false;
                    this.success = false;
                    this.message = 'Error'
                })
        },
        resetAccount() {
            this.loading2 = true;
            accountResetRequest().then(res => {
                this.message = 'Correo para restablecer cuenta enviado'
                this.alert = true;
                this.success = true;
                this.loading2 = false;
                setTimeout(() => {
                    this.alert = false
                    this.$router.push('/login')
                }, 3000);
                }).catch(e => {
                    this.loading2 = false;
                    this.success = false;
                    this.message = 'Error'
                })
        }
    },
}
</script>

<style lang="scss" scoped>
.Security {
    border-radius: 15px;
    padding: 20px;
    background-color: #F7F1F1;
}

.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
    33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
    66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
    100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}

</style>