<template>
    <main style="background-color: #F7B53F; min-height: 100vh; display:flex; flex-direction: column; justify-content: center; align-items:center;">
        <img style="width:100px; margin: 0 auto;" :src="require('../../assets/images/logo-region.png')"/>
        <div v-if="device == 'desktop'" class="button-wrapper">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.naturalphone.regionconectada"><img class="googleplay" src="@/assets/googleplay.png" alt=""></a>
          <a target="_blank" href="https://apps.apple.com/us/app/regi%C3%B3n-conectada/id6449751083"><img class="appstore" src="@/assets/appstore.svg" alt=""></a>
        </div>
        <div v-else class="loader"></div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            device: ''
        }
    },
    mounted() {
        if (window.navigator.userAgent.includes('Android')) {
            window.location.replace('https://play.google.com/store/apps/details?id=com.naturalphone.regionconectada')
        } else if (window.navigator.userAgent.includes('Mac')) {
            window.location.replace('https://apps.apple.com/us/app/regi%C3%B3n-conectada/id6449751083')
        } else {
            this.device = 'desktop';
        }
    }, 
}
</script>

<style lang="scss" scoped>
.loader {
  width: fit-content;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 30px;
  padding-bottom: 8px;
  background: linear-gradient(currentColor 0 0) 0 100%/0% 3px no-repeat;
  animation: l2 2s linear infinite;
}
.loader:before {
  content:"Redirigiendo..."
}
@keyframes l2 {to{background-size: 100% 3px}}

.googleplay {
  width: 200px;
  height: 80px;
}
.appstore {
    width: 170px;
}
.button-wrapper {
  display: flex;justify-content: center;
  align-items: center;
  gap: 12px;
}
</style>