<template>
  <main>
    <section class="herobg" id="hero" style="position:relative;">
        <div class="mx-auto logos" style="max-width: 400px; padding: 0;">
            <div style="display:flex;gap: 20px; align-items:center;padding: 0; flex-wrap:wrap;">
          <v-img
            :src="require('../../assets/logo-valcar.svg')"
            class="my-3"
            contain
            style="width:10%"
          />
          <v-img
            :src="require('../../assets/logo-arica.png')"
            class="my-3"
            contain
            style="width:10%"
          />
          <v-img
            :src="require('../../assets/logo-core.png')"
            class="my-3"
            contain
            style="width:27%"
          />
            </div>
        </div>
      <h1 class="Hero__Video__Title container d-none d-md-flex" style="color:red;">Proyecto denominado “Levantamiento Información Situación Delictual e Incivilidades Región de Arica y Parinacota”, financiado por el Gobierno Regional de Arica y Parinacota

      </h1>
      <div class="Hero container" style="position:relative;">


        <div class="Hero__Video">
          <!-- <h1 class="Hero__Video__Title d-none d-md-flex" style="color:red;">Proyecto denominado “Levantamiento Información Situación Delictual e Incivilidades Región Arica y Parinacota”, financiado por el Gobierno Regional.</h1> -->

          <div class="iframeContainer">
            <iframe
              class="Hero__Video__Iframe"
              src="https://www.youtube.com/embed/P_hCyCli-g4?rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <a  class="youtube-btn" style="display:flex; margin: 0 auto; margin-top: 20px;" href="https://www.youtube.com/channel/UCZxQmE8InwzZNkcXPZKaJ-g" target="_blank">
          <img style="margin: 0 auto;;border-radius: 25px;width:220px;" src="../../assets/images/regionyoutube.jpeg" />
        </a>
        </div>

        <!-- FORMULARIO REGISTRO -->
        <div class="container-heroRegistro">
          <h1 class="Hero__Video__Title d-md-none">Proyecto denominado “Levantamiento Información Situación Delictual e Incivilidades Región de Arica y Parinacota”, financiado por el Gobierno Regional de Arica y Parinacota</h1>

        <div class="Hero__Registro">
          <div style="display: flex; margin-left: 12px">
            <img src="../../assets/images/region.svg" alt="Región Conectada" />
            <h2 class="Hero__Registro__Title">Registrarme</h2>
          </div>
          <hr />
            <div v-if="!logeado">
          <div class="Hero__Registro__Field">
            <v-icon large color="#F64609"> mdi-account-outline </v-icon>
            <div style="padding-left: 12px; width: 50%">
              <label class="Hero__Registro__Field__label">Nombre</label>
              <v-text-field
                required
                :rules="requiredRule"
                v-model="form.name"
                class="Hero__Registro__Field__Input"
              ></v-text-field>
            </div>
            <div style="padding-left: 12px; width: 50%">
              <label class="Hero__Registro__Field__label">Apellido</label>
              <v-text-field
                required
                :rules="requiredRule"
                v-model="form.lastname"
                class="Hero__Registro__Field__Input"
              ></v-text-field>
            </div>
          </div>

          <div class="Hero__Registro__Field">
            <img
              class="Hero__Registro__Field__Icon"
              src="../../assets/images/Iconmail.svg"
              alt=""
            />
            <div style="padding-left: 12px; width: 100%">
              <label class="Hero__Registro__Field__label">Correo</label>
              <v-text-field
                :rules="emailRules"
                required
                v-model="form.email"
                class="Hero__Registro__Field__Input"
              ></v-text-field>
            </div>
          </div>
          <div class="Hero__Registro__Field">
            <img
              class="Hero__Registro__Field__Icon"
              src="../../assets/images/Iconphone.svg"
              alt=""
            />
            <div style="padding-left: 12px; width: 100%">
              <label class="Hero__Registro__Field__label">Teléfono</label>
              <div style="display:flex; align-items:center;">
                <p style="margin:0; margin-bottom:8px; margin-right:8px;">+569</p>
              <v-text-field
              :rules="requiredPhone"
                v-model="form.phone"
                :counter="8"
                maxlength="8"
                class="Hero__Registro__Field__Input"
              ></v-text-field>
              </div>
            </div>
          </div>
          <div class="Hero__Registro__Field">
            <img
              class="Hero__Registro__Field__Icon"
              src="../../assets/images/Iconlock.svg"
              alt=""
            />
            <div style="padding-left: 12px; width: 100%">
              <label class="Hero__Registro__Field__label">Contraseña</label>
              <v-text-field
                :type="passType ? 'text' : 'password'"
                required
                validate-on-blur
                :append-icon="passType ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (passType = !passType)"
                v-model="form.password"
                class="Hero__Registro__Field__Input"
              ></v-text-field>
            </div>
          </div>
          <div class="Hero__Registro__Field">
            <img
              class="Hero__Registro__Field__Icon"
              src="../../assets/images/Iconlock.svg"
              alt=""
            />
            <div style="padding-left: 12px; width: 100%">
              <label class="Hero__Registro__Field__label"
                >Repetir contraseña</label
              >
              <v-text-field
                :type="passType ? 'text' : 'password'"
                required
                validate-on-blur
                :append-icon="passType ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (passType = !passType)"
                v-model="password_confirmation"
                class="Hero__Registro__Field__Input"
              ></v-text-field>
            </div>
          </div>
          <hr />
          <small
            style="margin-left: 12px"
            v-for="error in errMessage"
            :key="error"
            >* {{ error }}</small
          >
          </div>

          <div v-else>
            <h3 class="Dialog__Title" style="padding-left: 12px;">
                Si eres un afortunado ganador Recibirás un correo
            </h3>
          </div>

          <button v-if="!logeado" class="Hero__Registro__Button" @click="register">
            Registrarme
          </button>
          <button v-if="logeado" class="Hero__Registro__Button" v-on="logeado && enviado ? {click: () =>logout()} : {click: () => dialog = true}">
            {{logeado && enviado ? 'Registrar Otro Usuario' : 'Invitar'}}
          </button>
        </div>
              <div class="hero-links">
        <a href="https://apps.apple.com/us/app/regi%C3%B3n-conectada/id6449751083">
          <img src="../../assets/images/appstore.png" alt="" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.naturalphone.regionconectada">
          <img src="../../assets/images/googleplay.png" alt="" />
        </a>
      </div>
        </div>


<!--       <a href="#hero">
        <img
          class="camara"
          src="../../assets/images/camara.png"
          alt="Registrate"
        />
      </a> -->

<!--         <a  class="youtube-btn" href="https://www.youtube.com/channel/UCZxQmE8InwzZNkcXPZKaJ-g" target="_blank">
          <img style="border-radius: 25px;" src="../../assets/images/regionyoutube.jpeg" />
        </a> -->

      </div>
      <div class="sigue-bajando">
<!--         <p style="color: #fff; margin-bottom: 0;">
          Sigue bajando y conoce todo sobre
        </p>
        <p style="color: #fff; font-weight: 800; margin-bottom:0;">Región Conectada</p> -->

        <a href="#separte">
          <v-icon large color="white"> mdi-chevron-double-down </v-icon>
        </a>
      </div>
    </section>

<!--     <section class="Video" id="Video">
      <div class="iframeContainer2">
        <iframe
          class="Video__Iframe"
          src="https://www.youtube.com/embed/keok3fA0V4s?rel=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </section> -->

    <section class="QueEsRegion" id="separte">
      <img
      style="object-fit: contain;"
        class="QueEsRegion__Img"
        src="../../assets/images/camara_celular.png"
        alt="App Región Conectada"
      />

      <div class="QueEsRegion__Text container">
        <h2 class="QueEsRegion__Text__Title">Sé parte de la red de Región Conectada.</h2>
        <p class="QueEsRegion__Text__Parrafo">
          El Viernes 20 de Octubre, el Gobierno Regional de Arica y Parinacota junto a Fundación Valcar 
			    lanzaron la Aplicación Móvil de Seguridad Preventiva “REGIÓN CONECTADA”.
        </p>
        <p class="QueEsRegion__Text__Parrafo">
			    Entre los primeros 1.000 nuevos registrados se sortearán 50 cámaras IP, para así poder monitorear su hogar o negocio desde cualquier 
			    lugar a través de esta aplicación la que además le permitirá realizar denuncias de delitos e incivilidades y 
			    comunicarse directamente con operadores a través de video conferencia, contará además con la posibilidad de crear 
			    su propia red de seguridad con personas de su confianza que le darán el primer apoyo ante un hecho delictual 
			    que haya sufrido o que haya sido testigo.
        </p>
        <p class="QueEsRegion__Text__Parrafo">
          Los ganadores serán publicados en la página WWW.REGIONCONECTADA.COM.
        </p>
      </div>
    </section>
    
    <section class="QueEsRegion QueEsRegion--reverse">
      <img
        class="QueEsRegion__Img"
        src="../../assets/images/regionapp.f81c5fda2.png"
        alt="App Región Conectada"
      />

      <div class="QueEsRegion__Text container">
        <h2 class="QueEsRegion__Text__Title">Qué es Región Conectada</h2>
        <p class="QueEsRegion__Text__Parrafo">
          Región Conectada es una innovadora Aplicación Móvil diseñada para
          proporcionar a los ciudadanos de la Región una herramienta vital en
          situaciones de emergencia. Con esta aplicación, podrás acceder a una
          Alarma personal que te brindará tranquilidad y seguridad ante cualquier
          situación o emergencia imprevista que tenga.
        </p>
        <p class="QueEsRegion__Text__Parrafo">
          Pero eso no es todo. Región Conectada va más allá al permitirte
          informar sobre cualquier incivilidad que presencies en tu entorno.
          Podrás reportar de manera rápida y eficiente, contribuyendo a mantener
          la seguridad y el orden en tu comunidad. Lo más destacado de esta aplicación es la posibilidad de crear tu
          propia Red de Seguridad personal. Invita a tus familiares, amigos y vecinos a
          formar parte de ella, y juntos estarán preparados para enfrentar
          momentos de aflicción personal. A través de comunicaciones simultáneas
          de video conferencia, podrán brindarse apoyo y contención mutua,
          generando un sentido de comunidad y protección.
        </p>

        <p class="QueEsRegion__Text__Parrafo">
          Región Conectada se convierte así en un valioso recurso para
          fortalecer los lazos entre los habitantes de la Región, fomentando la
          colaboración y la solidaridad en momentos de crisis.
        </p>
      </div>
    </section>

    <section class="Footer">
      <h2 class="Footer__Title">¿Aún no la tienes?</h2>
      <div class="Footer__Images">
        <a href="https://apps.apple.com/us/app/regi%C3%B3n-conectada/id6449751083">
          <img src="../../assets/images/appstore.png" alt="" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.naturalphone.regionconectada">
          <img src="../../assets/images/googleplay.png" alt="" />
        </a>
      </div>
      <p class="Footer__Parrafo">
        Descarga la Aplicación ahora mismo y descubre cómo puedes garantizar tu
        Seguridad y la de tus seres queridos, al mismo tiempo que contribuyes a
        construir una Región más conectada y protegida.
      </p>
    </section>

    <!-- DIALOG -->
    <v-dialog v-model="dialog" width="500">
      <section class="Dialog">

        <div v-if="errorEnviar">
          <img src="" alt="" />
          <h3 class="Dialog__Title">{{errorEnviar}}</h3>
        </div>
        <div v-if="enviado">
          <img src="" alt="" />
          <h3 class="Dialog__Title">¡Felicidades ya esta participado!</h3>
          <h3 class="Dialog__Title">
            Si eres un afortunado ganador Recibirás un correo
          </h3>
        </div>

        <div v-else>
          <h3 class="Dialog__Title">
            Estás a punto de ganar. Invita a 3 contactos
          </h3>
          <hr />

          <div>
            <img src="" alt="" />
            <div>
              <div class="Hero__Registro__Field">
                <img
                  class="Hero__Registro__Field__Icon"
                  src="../../assets/images/Iconmail.svg"
                  alt=""
                />
                <div style="padding-left: 12px; width: 100%">
                  <label class="Hero__Registro__Field__label">Correo</label>
                <v-text-field
                :rules="emailRules"
                required
                v-model="invitations.email1"
                class="Hero__Registro__Field__Input"
              ></v-text-field>
                </div>
              </div>
              <div class="Hero__Registro__Field">
                <img
                  class="Hero__Registro__Field__Icon"
                  src="../../assets/images/Iconmail.svg"
                  alt=""
                />
                <div style="padding-left: 12px; width: 100%">
                  <label class="Hero__Registro__Field__label">Correo</label>
                <v-text-field
                :rules="emailRules"
                required
                v-model="invitations.email2"
                class="Hero__Registro__Field__Input"
              ></v-text-field>
                </div>
              </div>
              <div class="Hero__Registro__Field">
                <img
                  class="Hero__Registro__Field__Icon"
                  src="../../assets/images/Iconmail.svg"
                  alt=""
                />
                <div style="padding-left: 12px; width: 100%">
                  <label class="Hero__Registro__Field__label">Correo</label>
                <v-text-field
                :rules="emailRules"
                required
                v-model="invitations.email3"
                class="Hero__Registro__Field__Input"
              ></v-text-field>
                </div>
              </div>

              <button :disabled="!invitations.email1 || !invitations.email2 || !invitations.email3" class="Hero__Registro__Button mt-4" @click="invite">
                Enviar
              </button>
            </div>
          </div>
        </div>
      </section>
    </v-dialog>
  </main>
</template>

<script>
import {
  signUp,
  login,
  addUserToNetwork,
} from "@/helpers/api/sosdenuncias/sosdenuncias";

export default {
  data() {
    return {
      logeado: false,
      dialog: false,
      enviado: false,
      passType: false,
      errMessage: false,
      errorEnviar: false,
      form: {
        name: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
      },
      password_confirmation: "",
      requiredRule: [(v) => !!v || "Este campo es obligatorio"],
      emailRules: [
        (v) => !!v || "Email es obligatorio",
        (v) => /.+@.+\..+/.test(v) || "Email debe ser válido",
      ],
      passwordRules: [
        (v) => !!v || "Contraseña es requerida",
        (v) =>
          (v && v.length >= 8) ||
          "La contraseña debe ser de 8 caracteres mínimo",
      ],
      requiredPhone: [
        v => !!v || 'Este campo es obligatorio',
        v => ( !!v && /^\d+$/.test(v) ) || 'Ingrese sólo números'
      ],
      invitations: {
        email1: "",
        email2: "",
        email3: "",
      },
    };
  },
  computed: {
    passwordConfirmRules() {
      return [
        this.password_confirmation !== "" ||
          "Confirmación de contraseña es requerida",
        this.password_confirmation === this.form.password ||
          "Las contraseñas no coinciden",
      ];
    },
  },
  methods: {
    register() {
      const userTokenSOSDenuncias = localStorage.getItem("userTokenSOSDenuncias");
      if (!userTokenSOSDenuncias) {
        const data = {
          ...this.form,
          password_confirmation: this.password_confirmation,
        };
        signUp(data)
          .then((res) => {
            login({ email: this.form.email, password: this.form.password });
            this.enviado = false;
            this.dialog = true;
            this.logeado = true;
            this.errMessage = false;
          })
          .catch((e) => {
            this.errMessage = e.response.data;
          });
      } else {
        this.dialog = true;
      }
    },
    invite() {
      Promise.all([
        addUserToNetwork({ guest_email: this.invitations.email1 }),
        addUserToNetwork({ guest_email: this.invitations.email2 }),
        addUserToNetwork({ guest_email: this.invitations.email3 }),
      ])
        .then((res) => {
        this.errorEnviar = false;
          this.enviado = true;
        })
        .catch((e) => {
          this.errorEnviar = "Ha ocurrido un error";
        });
    },
    logout() {
      localStorage.removeItem("userTokenSOSDenuncias");
      this.enviado = false;
      this.logeado = false;
      this.invitations = {
        email1: "",
        email2: "",
        email3: "",
      }
      this.form = {
        name: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
      }
      this.password_confirmation = ""
    }
  },
  mounted() {
    const userTokenSOSDenuncias = localStorage.getItem("userTokenSOSDenuncias");
    if (userTokenSOSDenuncias) {
      this.dialog = true;
      this.logeado = true;
    }
  },
};
</script>

<style lang="scss" scoped>
a {
  margin: 0;
  padding: 0;
}
input {
  padding-left: 5px;
}
.herobg {
  background-image: url("../../assets/images/herobg.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  max-height: 1200px;
  height: 700px;
  min-height: 115vh;
}
.hero-links {
    display: flex;
    align-items: center;
}
.youtube-btn {
  display: flex;
  margin-top: 20px;
  width: 220px !important;
  
}
//Hero
.container-heroRegistro {
    min-width: 200px;
    max-width: 460px;
    width: 100%;
        margin: 0 auto;
}

@media (max-width: 568px) {
  .container-heroRegistro {
    margin-top: 0;
  }
}

.Hero {
  display: flex;
  justify-content: flex-end;
  gap: 50px;
    max-height: 1200px;
  height: 700px;
  min-height: 115vh;
  &__Video {
    max-width: 1100px;
    width: 1100px;
    &__Title {
      color: red;
      font-size: 20px;
      text-align: center;
      margin-top: 0;
      padding-top: 0;
      line-height: 30px;
      display: flex;
      justify-content: center;
    }
    &__Iframe {
      border: 1px solid #707070;
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__Registro {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 24px;
    width: 100%;
    min-width: 200px;
    max-width: 460px;
    height: fit-content;
    padding-bottom: 20px;
    &__Title {
      color: #000000;
      font-size: 32px;
      margin-left: 50px;
    }
    &__Field {
      display: flex;
      align-items: center;
      margin-left: 12px;
      width: 90%;
      &__label {
        font-size: 10px;
        font-weight: normal;
      }
      &__Input {
        margin-bottom: 12px;
        background: #f7f7f8 0% 0% no-repeat padding-box;
        border: 1px solid #e4e4e4;
        border-radius: 10px;
        width: 100%;
        height: 40px;
        padding-top: 4px;
        padding-left: 5px;
      }
    }
    &__Button {
      background: transparent linear-gradient(100deg, #ff6200 0%, #e50f1a 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 11px;
      color: #ffffff;
      padding: 10px;
      width: 90%;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      margin-bottom: 5px;
      margin: auto;
    }
  }
}


@media (max-width: 900px) {
  .Hero {
  max-height: 1200px;
  height: 1200px;
  min-height: 140vh;
  max-height: 180vh;
    flex-direction: column-reverse;
    gap: 12px;
    &__Video {
      margin-top: 70px;
      width: auto;
      &__Title {
        display: flex;
        justify-content: center;
        padding-top: 0;
      }
    }
    &__Registro {
      margin: 0 auto;
      height: 550px;
      height: fit-content;
      padding-bottom: 20px;
    }
  }
}
.iframeContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.camara {
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: 377px;
  cursor: pointer;
}
@media (max-width: 568px) {
.herobg {
  max-height: 1200px;
  height: 1200px;
  min-height: 140vh;
  max-height: 180vh;
}
  .camara {
    display: none;
  }
.hero-links {
  position: absolute;
  right: 50%;
  transform: translate(50%, 0);
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 12px;
}
.youtube-btn {
      margin-top: 20px;
    display: flex;
}
}

.sigue-bajando {
  position: absolute;
  bottom: 0;
/*   bottom: -46px; */
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}
@media (max-width: 900px) {
  .sigue-bajando {
    display: none;
  }
}
hr {
  border: 1px solid #e4e4e4;
}

//Video
.iframeContainer2 {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 44.25%;
  height: 100vh;
}
.Video {
  height: 100vh;
  &__Iframe {
    border: 1px solid #707070;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
  }
}

@media (max-width: 568px) {
  .iframeContainer2 {
    height: 300px;
    margin-top: 20px;
  }

  .Video {
    height: 300px;
    &__Iframe {
      height: 300px;
    }
  }
}

// QUE ES REGION CONECTADA
.QueEsRegion {
  margin-top: 20px;
  display: flex;
  min-height: 100vh;
  gap: 40px;
  margin-right: 20px;
    margin-left: 20px;
  &--reverse {
    margin-left: 20px;
    flex-direction: row-reverse;
  }
  &__Img {
    width: 90%;
  }
  &__Text {
    margin-top: 40px;
    &__Title {
      color: #e4562a;
    }
    &__Parrafo {
      color: #707070;
      text-align: justify;
    }
  }
}
@media (max-width: 900px) {
  .QueEsRegion {
    flex-direction: column-reverse;
    gap: 50px;
    &__Text {
      margin-top: 0;
      &__Title {
        margin-top: 0;
      }
    }
  }
}

//Footer
.Footer {
  background: transparent url("../../assets/images/Rectángulo 2352.png") 0% 0%
    no-repeat padding-box;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 378px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__Title {
    font-weight: 900;
  }
  &__Parrafo {
    max-width: 497px;
    text-align: center;
    color: #fff;
  }
  &__Images {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 568px) {
  .Footer {
    flex-direction: column;
    height: 450px;
    max-height: 500px;
    &__Images {
      flex-direction: column;
    }
    &__Parrafo {
      margin: 0px 26px;
    }
  }
}

.Dialog {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 24px;
  padding: 12px;
  &__Title {
    color: #000000;
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

@media (min-width: 1800px) {
  .herobg {
    min-height: 100vh;
    height: 100vh;
  }
}

  .logos {
    height: 100px;
  }
@media (min-width:568px) {
  .logos {
    height: 150px;
  }
}
</style>
