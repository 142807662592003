import { render, staticRenderFns } from "./Promocion.vue?vue&type=template&id=20717579&scoped=true&"
import script from "./Promocion.vue?vue&type=script&lang=js&"
export * from "./Promocion.vue?vue&type=script&lang=js&"
import style0 from "./Promocion.vue?vue&type=style&index=0&id=20717579&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20717579",
  null
  
)

export default component.exports