import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"d-flex flex-column justify-center",staticStyle:{"background-color":"#F7B53F"},attrs:{"fluid":"","fill-height":""}},[_c(VSnackbar,{attrs:{"vertical":"","bottom":"","center":"","color":_vm.messageColor},model:{value:(_vm.showMessage),callback:function ($$v) {_vm.showMessage=$$v},expression:"showMessage"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.message))])]),_c('img',{staticStyle:{"max-width":"250px","width":"100%"},attrs:{"src":require('@/assets/images/logo-region.png')}}),_c('div',{staticClass:"login-form-container d-flex justify-center mt-8"},[_c(VForm,{ref:"form",staticClass:"d-flex flex-column flex-grow-1",staticStyle:{"max-width":"400px","width":"100%"},attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"rules":_vm.emailRules,"label":"Email","required":"","dark":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VBtn,{attrs:{"disabled":!_vm.valid,"color":"#5E85EF","dark":"","loading":_vm.loading},on:{"click":_vm.requestResetPass}},[_vm._v(" Reestablecer Contraseña ")]),_c(VBtn,{staticClass:"mt-4",attrs:{"disabled":!_vm.valid || _vm.loading,"color":"white","small":"","text":""},on:{"click":function($event){return _vm.$router.push({name: 'Home'})}}},[_vm._v(" Volver al Inicio ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }