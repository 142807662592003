<template>
    <section class="Start2FA">
    <div v-if="!success">
    <v-form v-if="!codeSent" ref="form" v-model="valid" lazy-validation>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-text-field placeholder="56912345678" maxlength="11" :counter="11" :rules="requiredPhone" required v-model="phone_number" label="Teléfono"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <label>Método de Verificación:</label>
                    <v-radio-group v-model="type">
                        <v-radio
                            label="SMS"
                            value="1"
                        ></v-radio>
                        <v-radio
                            label="Whatsapp"
                            value="2"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <div style="display:flex; margin:0 auto; width: 100%; max-width: 600px">
                <v-btn block :loading="loadingbtn" :disabled="loadingbtn" @click="startVerify" color="primary">Siguiente</v-btn>
            </div>
        </v-container>
    </v-form>
    
    <v-form v-else ref="form2" v-model="valid2" lazy-validation>
        <v-container>
        <h3 style="margin-top: 0;">Ingresa tu código de verificación</h3>
        <p>Recibiste un código de 6 números por {{validationType}}</p>
            <v-row>
                <v-col cols="12">
                    <v-text-field placeholder="123456" maxlength="6" :counter="6" :rules="requiredCode" v-model="code" label="Código"></v-text-field>
                </v-col>
            </v-row>
            <div style="display:flex; margin:0 auto; width: 100%; max-width: 600px; margin-top: 20px;">
                <v-btn block :loading="loadingbtn" :disabled="loadingbtn" @click="verify" color="primary">Verificar</v-btn>
            </div>
        </v-container>
    </v-form>
    </div>

    <div v-else>
        <h3 style="margin-top: 0; margin: 0 auto; justify-content:center; gap: 6px;display:flex;"><v-icon large color="success">mdi-check-circle</v-icon>Cuenta Verificada</h3>
        <div style="display:flex;justify-content:center; width:100%; margin-top: 20px;">
            <v-btn @click="$router.push('/')" text>Ir al Inicio</v-btn>
        </div>
    </div>
    </section>
</template>

<script>
import {startVerifyUserPhoneNumber, verifyUserPhoneNumber} from '../../../helpers/api/sosdenuncias/sosdenuncias'
export default {
    data () {
        return {
        phone_number: '',
        code:'',
        type: "1",
        valid: true,
        valid2: true,
        requiredPhone: [
            v => !!v || 'Este campo es obligatorio',
            v => ( !!v && /^\d+$/.test(v) ) || 'Ingrese sólo números',
            v => ( !!v && /^.{11}$/.test(v) ) || 'El campo debe tener 11 números',
        ],
        requiredCode: [
            v => !!v || 'Este campo es obligatorio',
            v => ( !!v && /^\d+$/.test(v) ) || 'Ingrese sólo números',
            v => ( !!v && /^.{6}$/.test(v) ) || 'El campo debe tener 6 números',
        ],
        loadingbtn: false,
        codeSent: false,
        success:false,
        message: ''
        }
    },
    computed: {
        validationType() {
            const types = {
                1:"SMS",
                2:"Whatsapp"
            }
            return types[+this.type]
        },
        disableCodeBtn() {
            if (!this.code || this.code.length !== 6 || /^\d+$/.test(this.code)) {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        startVerify() {
            const data = {
                phone_number : this.phone_number,
                type: +this.type
            }

        if(this.$refs.form.validate()){
            this.loadingbtn = true;
            startVerifyUserPhoneNumber(data).then(res => {
            this.loadingbtn = false;
            this.codeSent = true;
          }).catch(function (error) {
            this.loadingbtn = false;
            this.message = res.message || 'Error'
            this.codeSent = false;
            });
        }
        },
        verify() {
            const data = {
                phone_number: this.phone_number,
                code: this.code
            }
            if(this.$refs.form2.validate()){
            this.loadingbtn = true;
            verifyUserPhoneNumber(data).then(res => {
            if (res.code == 200) {
                this.loadingbtn = false;
                this.success = true;
            } else {
                this.loadingbtn = false;
            }

        }).catch(function (error) {
            this.loadingbtn = false;
            this.message = res.message || 'Error'
            });
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.Start2FA {
    border-radius: 15px;
    padding: 20px;
    background-color: #F7F1F1;
    margin-top: 100px;
    max-width: 800px;
    margin: 0 auto;
}
</style>