<template>
<div>
    <section v-if="!loader" class="Cards">
        <div class="Cards__Card" v-for="(device, i) in devices" :key="device.id">
            <div class="Cards__Card__Header">
                <h4>{{ device.name }}</h4>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="red" large class="Cards__Card__Delete" v-bind="attrs" v-on="on">
            <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
              </template>
              <span>Eliminar</span>
    </v-tooltip>
            </div>

    <div class="Cards__Card__Body">
            <h5>Marca: {{ device.brand }}</h5>
            <h5>Modelo: {{ device.model }}</h5>
            <h5>Sistema: {{ device.system }}</h5>
            <h5>Versión del Sistema: {{ device.system_version }}</h5>
            <div style="display:flex; align-items:center;height: 30px;">
                <h5 style="margin-right: 14px;">Visualización Continua en Panel:</h5>
                <v-switch @click="setDeviceConfig(device)" v-model="devices[i].config.fixed_to_panel"></v-switch>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-2" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span style="width: 300px !important;">Lorem ipsum dolor, sit amet consectetur adipisicing elit. </span>
                </v-tooltip>    
    </div>
            </div>
        </div>
    </section>

    <section v-else style="display:flex; justify-content:center;">
        <div class="loader"></div>
    </section>
    </div>
</template>

<script>
import { getUserDevices, updateDeviceConfig } from '../../../helpers/api/sosdenuncias/sosdenuncias'
export default {
    data () {
        return {
            devices: [],
            loader: true
        }
    },
    methods: {
        getDevices() {
            getUserDevices().then(res => {
                this.devices = res.devices
                this.loader = false;
            })
        },
        setDeviceConfig(device) {
            console.log(device);
            const data = {
                device_id: device.id,
/*                 company_id: device.config.company_id, */
                fixed_to_panel: device.config.fixed_to_panel
            }
            updateDeviceConfig(data).then(res => {
                console.log(res);
            })
        }
    },
    mounted() {
        this.getDevices()
    }
}
</script>

<style lang="scss" scoped>
    .Cards {
        flex-wrap: wrap;
        gap: 20px;
        &__Card {
            position: relative;
            border-radius: 15px;
            background-color: #F7F1F1;
            width: 30%;
            min-width: 260px;
            max-width: 500px;
            &__Header {
                padding: 12px 20px;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                background-color: 	#F3E5AB;
                display: flex;
                justify-content: space-between;
                align-items: center;
                h4{
                    color: #000;
                }
            }
            &__Body {
                padding: 20px;
                padding-top: 8px;
            }
/*             &__Delete {
            } */
        }
    }

/*     @media (max-width: 568px) {
            .Cards {
        &__Card {
            border-radius: 15px;
            background-color: #F7F1F1;
            width: 20%;
            min-width: 300px;
            max-width: 500px;
        }
    }
    } */

    .loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
    33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
    66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
    100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}
</style>