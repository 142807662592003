<template>
  <div class="footer wf-section">
    <div class="wrap"><img src="@/assets/images/Logo-1.png" width="99" sizes="98.98896789550781px" srcset="@/assets/images/Logo-1-p-500.png 500w, @/assets/images/Logo-1-p-800.png 800w, @/assets/images/Logo-1-p-1080.png 1080w, @/assets/images/Logo-1.png 1455w" alt="">
      <div class="footer-list">
        <a href="pdf/PreguntasfrecuentesRC.pdf" class="footer-link">Preguntas Frecuentes</a>        
        <a href="/terminos" class="footer-link">Términos y Condiciones</a>        
        <a href="/politicas" class="footer-link">Políticas de Privacidad</a>          
        <a href="pdf/PresentacionaplicacionRC.pdf" class="footer-link">Presentación APP</a>   
            <router-link :to="{ path: '/ganadores-camaras'}" >Sorteos</router-link>
        <!--a href="#" class="footer-link">Instagram</a>
        <a href="#" class="footer-link">Facebook</a>
        <a href="#" class="footer-link">Twitter</a-->
 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>

a {
  text-decoration: none;
  color: #fff;
}
</style>
