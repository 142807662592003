<template>
    <main style="background-color: #F7B53F; min-height: 100vh; position:relative;">
<!--     Config Nav -->
    <v-navigation-drawer style="position: fixed; z-index: 999;" permanent expand-on-hover>
        <v-list>
          <v-list-item class="px-2">
            <img style="width:100px; margin: 0 auto;" src="../../../assets/images/logo-region.png"/>
          </v-list-item>

<!--           <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6 text-center" v-if="userInfo">
                {{userInfo.profile.name}} {{userInfo.profile.lastname}}
              </v-list-item-title>
              <v-list-item-subtitle class="text-center" v-if="userInfo">{{userInfo.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item> -->
        </v-list>

        <v-divider></v-divider>

        <v-list nav dense>
            <div @click="selectConfig(1)">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cuenta</v-list-item-title>
          </v-list-item>
            </div>
            <div @click="selectConfig(2)">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-tablet-cellphone</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dispositivos</v-list-item-title>
          </v-list-item>
            </div>
            <div @click="selectConfig(3)">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-web</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Redes de Seguridad</v-list-item-title>
          </v-list-item>
          </div>
          <div @click="selectConfig(4)">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-shield-lock-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Privacidad y Seguridad</v-list-item-title>
          </v-list-item>
          </div>

          <v-list-item link>
            <div @click="logOut" style="display:flex;">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Salir</v-list-item-title>
            </div>
          </v-list-item>
        </v-list>
    </v-navigation-drawer>

    <v-container class="container">
    <section class="Config">
        <div style="z-index: 9999; color:black; display:flex; justify-content:center;">
        <div style="display:flex; width:100%;">
        <v-icon style="width:60px; border-right: 1.5px solid #dbd9d9;" x-large>{{icon}}</v-icon>
        <h2 class="Config__Title" style="color:#000; margin-left: 12px;">{{title}}</h2>
        </div>
    </div>
    </section>

</v-container>
    <section class="container">
    <UserConfig  v-if="currentConfig == 1" :loader="loader" :userInfo="userInfo" :editedUserInfo="editedUserInfo"/>
    <DevicesConfig v-if="currentConfig == 2" />
    <NetworksConfig v-if="currentConfig == 3" />
    <SecurityConfig v-if="currentConfig == 4" :userInfo="userInfo" />
    <start2FA v-if="currentConfig == 5"/>

    <v-alert v-show="!twoFA" transition="scale-transition" @click="selectConfig(5)" style="cursor:pointer; max-width: 400px; left:50%; transform: translate(-50%, 0);position:absolute; bottom: 20px; " color="orange" elevation="4" type="info">Activar Auntenticación en Dos Pasos <v-icon dark right>mdi-arrow-right-thin</v-icon></v-alert>
    </section>
    </main>
</template>

<script>
import { GetLoggedUserInfo, getUserConfirmedPhones,  logout } from '../../../helpers/api/sosdenuncias/sosdenuncias'
import UserConfig from './UserConfig.vue'
import DevicesConfig from './DevicesConfig.vue'
import NetworksConfig from './NetworksConfig.vue'
import SecurityConfig from './SecurityConfig.vue'
import start2FA from '../2FA/start2FA.vue'
export default {
    components: {UserConfig, DevicesConfig, NetworksConfig, SecurityConfig, start2FA},
    data () {
        return {
            userInfo: {},
            editedUserInfo: {},
            currentConfig: 1,
            loader: true,
            twoFA: true
        }
    },
    computed: {
      title() {
        const configs = {
          1: 'Configuración de la Cuenta',
          2: 'Configuración de Dispositivos',
          3: 'Configuración de Redes',
          4: 'Configuración de Privacidad y Seguridad',
          5: 'Autenticación en Dos Pasos'
        }
        return configs[this.currentConfig]
      },
      icon() {
        const configs = {
          1: 'mdi-account-cog-outline',
          2: 'mdi-tablet-cellphone',
          3: 'mdi-web',
          4: 'mdi-shield-lock-outline',
          5: 'mdi-two-factor-authentication'
        }
        return configs[this.currentConfig]
      }
    },
    methods: {
        GetUserInfo() {
            GetLoggedUserInfo().then(res => {
                this.userInfo = {...res.user}
                this.editedUserInfo = {...res.user}
                this.loader = false
            })
        },
        getConfirmedPhones() {
          getUserConfirmedPhones().then(res => {
            this.twoFA = res.masked_user_phones ? true : false;
          }).catch(e => {
            console.log(e);
          })
        },
        selectConfig(config) {
            this.currentConfig = config
        },
        logOut() {
            logout().then(res => {
                this.$router.go()
            })
        },
    },
    mounted() {
        const token = localStorage.getItem("userTokenSOSDenuncias");
        !token && this.$router.push({path: "/login",query: { redirect_to: this.$route.path }});
        this.GetUserInfo();
        this.getConfirmedPhones();
    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 1200px;
    max-width: calc(100vw - 50px) !important;
    margin-left: 50px;
}
/* @media (max-width: 568px) {
    .container {}
} */
.Config {
    background-color: #f7f1f1;
    border-radius: 15px;
    
    &__Title {
        font-size: 24px;
    }
}

@media (max-width: 568px) {
    .Config {
    background-color: #f7f1f1;
    &__Title {
        font-size: 14px;
    }
}
}
</style>