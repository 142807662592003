<template>
    <div>
    <section class="UserConfig" v-if="editedUserInfo && !loader">
        <div class="UserConfig__Header">
            <div class="UserConfig__Header__Data">
                <label for="file-input" style="position:relative;">
                    <v-icon color="info" style="position:absolute; right:0;bottom:0;cursor:pointer">mdi-camera</v-icon>
                <img class="UserConfig__Header__Data__Img" style="margin: 0 auto;" :src="editedUserInfo.profile.avatar_link ? editedUserInfo.profile.avatar_link : require('../../../assets/user.svg')"/>
                </label>
                    <input id="file-input" style="display:none;" @change="onChangeProfileImg" type="file"/>

                <div class="UserConfig__Header__Data__Text">
                    <h3 class="UserConfig__Header__Data__Text__Title">{{userInfo.profile.name}} {{userInfo.profile.lastname}}</h3>
                    <p class="UserConfig__Header__Data__Text__Subtitle">{{userInfo.email}}</p>
                </div>
            </div>
            <v-btn @click="disabledEdition = !disabledEdition" color="primary">Editar<v-icon right dark>mdi-pencil</v-icon></v-btn>
        </div>

        <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field required :rules="required" v-model="editedUserInfo.profile.name" :disabled="disabledEdition" label="Nombre"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field required :rules="required" v-model="editedUserInfo.profile.lastname" :disabled="disabledEdition" label="Apellido"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field maxlength="11" :counter="11" :rules="requiredPhone" required v-model="editedUserInfo.profile.phone" :disabled="disabledEdition" label="Teléfono"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field :rules="rut" @change="(rut) => {formatRut(rut)}" required v-model="editedUserInfo.profile.rut" :disabled="disabledEdition" label="Rut"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field required :rules="required" v-model="editedUserInfo.profile.address" :disabled="disabledEdition" label="Dirección"></v-text-field>
                    </v-col>
                </v-row>
                <v-btn :loading="loadingbtn" :disabled="disabledEdition" @click="saveUserData" color="success">Guardar</v-btn>

                <v-alert
                    v-if="alert"
                    :color="success = true ? 'green' : 'red'"
                    :type="success = true ? 'success' : 'error'"
                    style="display:flex; position: absolute;top: 20px;right: 5%;"
                    transition="slide-y-transition"
                    >{{message}}</v-alert>
            </v-container>
        </v-form>
    </section>

    <section v-else style="display:flex; justify-content:center;">
        <div class="loader"></div>
    </section>
    </div>
</template>

<script>
import { validate, format } from 'rut.js'
import { updateUserInfo } from '../../../helpers/api/sosdenuncias/sosdenuncias'
export default {
    props: {
        userInfo: {
            type: Object,
        },
        editedUserInfo: {
            type: Object,
        },
        loader: {
            type: Boolean,
        },
    },
    data () {
        return {
            disabledEdition: true,
            avatarImg: '',
            valid: true,
            rut: [
                v => (!!v || 'Este campo es obligatorio') && (validate(v) || 'Rut no válido')
            ],
            requiredPhone: [
            v => !!v || 'Este campo es obligatorio',
            v => ( !!v && /^\d+$/.test(v) ) || 'Ingrese sólo números'
          ],
            required: [v => !!v || 'Este campo es obligatorio',],
            loadingbtn: false,
            success: false,
            alert: false,
            message: '',
            editedImg: false,
        }
    },
    methods: {
        formatRut(rut) {
        this.editedUserInfo.profile.rut = format(rut)
      },
      saveUserData() {
          const data = new FormData();

            data.append("name", this.editedUserInfo.profile.name)
            data.append("lastname", this.editedUserInfo.profile.lastname)
            data.append("phone", this.editedUserInfo.profile.phone)
            data.append("rut", this.editedUserInfo.profile.rut)
            data.append("address", this.editedUserInfo.profile.address)
            if (this.editedImg) {
                data.append("avatar", this.avatarImg);
            }     

        if(this.$refs.form.validate()){
            this.loadingbtn = true;
            updateUserInfo(data).then(res => {
            this.loadingbtn = false;
            this.message = res.message
            this.alert = true;
            this.success = true;
            setTimeout(() => {
                this.alert = false
            }, 3000);
          }).catch(function (error) {
            this.loadingbtn = false;
            this.message = res.message || 'Error al Actualizar'
            this.alert = true;
            this.success = false;
            });
        }
      },
    onChangeProfileImg(e) {
        this.editedImg = true;
      this.editedUserInfo.profile.avatar_link = URL.createObjectURL(e.target.files["0"]);
      this.avatarImg = e.target.files["0"];
    },
    },
    
}
</script>

<style lang="scss" scoped>
    .UserConfig {
        border-radius: 15px;
        padding: 20px;
        background-color: #F7F1F1;
        &__Header{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &__Data {
            display: flex;
            align-items: center;
            &__Img {
                border-radius: 50%;
                object-fit: cover;
                width: 80px;
                cursor: pointer;
            }
            &__Text {
                margin-left: 20px;
                &__Title {
                    line-height: 24px;
                    text-transform: capitalize;
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 0;
                    margin-bottom: 0;
                }
                &__Subtitle {
                    line-height: 24px;
                    margin-bottom: 0;
                }
            }
        }
    }
    }

        .loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
    33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
    66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
    100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}
</style>