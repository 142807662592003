<template>
  <main>
    <div class="bg-navbar">
      <Header />
    </div>

    <div class="terminos-container">
      <h1>
        TÉRMINOS Y CONDICIONES DE USO DE LA APLICACIÓN “REGIÓN CONECTADA”.
      </h1>
      <p>
        Si Usted es usuario de la Aplicación Móvil
        <span>“REGIÓN CONECTADA”</span> y de su Portal WEB
        http://www.regiónconectada.com ha leído y aceptado los Términos y
        Condiciones de uso establecidos por la empresa
        <span>NATURALPHONE S.A.</span>, propietaria tanto de la Aplicación Móvil
        y del Portal WEB anteriormente mencionado, por lo tanto, se obliga a
        cumplir con todas las instrucciones contenidas en estos Términos y
        Condiciones.
      </p>
      <p>
        Asimismo y en consecuencia a lo anteriormente indicado, los datos
        personales que se obtengan a través de la Aplicación Móvil
        <span>“REGIÓN CONECTADA”</span> y/o Sitio WEB serán transferidos a
        <span>NATURALPHONE</span> (Compañía Propietaria de la Aplicación) y
        serán tratados con la finalidad de enviarle, entre otros, información
        referente a temas de seguridad, o cualquier tipo de comunicación sobre
        información relevante de prevención que Usted necesite conocer. Al
        aceptar los presentes términos y condiciones, Usted autoriza,
        expresamente la mencionada transferencia de datos personales y el
        tratamiento de los mismos con dicha finalidad.
      </p>
      <p>
        <span>NATURALPHONE S.A.</span>, puede cambiar cualquier parte o la
        totalidad de las Políticas para la utilización de la Aplicación Móvil
        <span>“REGIÓN CONECTADA”</span>
        y de su Portal WEB http://www.regiónconectada.com, a fin de adaptarlas a
        novedades legislativas, jurisprudencias, prácticas de la industria o
        políticas comerciales de la empresa. En dicho supuesto, será de su
        responsabilidad como usuario de la referida Aplicación Móvil y del Sitio
        WEB, revisar periódicamente las Políticas, como así también, sus
        modificaciones.
      </p>
      <p>
        Todas las reformas a las Políticas de <span>“REGIÓN CONECTADA”</span> y
        de su Portal WEB http://www.regiónconectada.com, tendrán efecto
        automático, una vez que hayan sido publicadas tanto en la Aplicación
        Móvil, o bien, en el Sitio WEB antes citado y se entenderá como
        aceptación por parte del usuario, si éste sigue ocupando la citada
        Aplicación Móvil y su Portal WEB una vez publicadas las modificaciones
        de su uso.
      </p>

      <h2>1. Admisión de Usuarios a “REGIÓN CONECTADA”.</h2>
      <p>
        Podrán utilizar los servicios de la Aplicación Móvil
        <span>“REGIÓN CONECTADA”</span>
        y de su Sitio WEB http://www.regiónconectada.com, cualquier usuario
        mayor de 18 años sin ningún tipo de restricción y en forma gratuita.
      </p>
      <p>
        Podrán ser suspendidos del uso de la Aplicación Móvil todo usuario, que
        haga mal uso ya sea de la Aplicación Móvil como de su Portal WEB, como
        así también, que publiquen en su perfil, fotografías o vídeos que
        contengan pornografía, pedofilia y toda imagen reñida con la moral de
        las personas, todo esto último también se aplica para las transmisiones
        en tiempo real que posibilitará la Aplicación Móvil.
      </p>
      <p>
        La Aplicación Móvil tendrá diferentes Servicios los cuales serán
        entregados a los usuarios, familiares, vecinos, entre otros, que hayan
        bajado el Aplicativo a su smartphone y que ocupen los servicios que
        ofrece el Sistema <span>“REGIÓN CONECTADA”</span>. Para poder acceder a
        los Servicios de la Aplicación Móvil y de su Sitio WEB, el usuario
        deberá aceptar las condiciones de uso de ésta Plataforma.
      </p>
      <p>
        <span>NATURALPHONE S.A.</span>, se reserva el derecho de inhabilitar a
        aquellos usuarios que en un período razonable de tiempo, no hagan
        utilización de la Aplicación Móvil <span>“REGIÓN CONECTADA”</span> como
        de su Sitio WEB http://www.regiónconectada.com. Asimismo,
        <span>NATURALPHONE S.A.</span>, tendrá el derecho de suspender o
        inhabilitar a usuarios que hayan ingresado datos que resultaron ser
        incompletos y/o incorrectos. Toda información suministrada por el
        usuario, llámese Antecedentes Personales y otra información, se usará de
        acuerdo a las Políticas de Privacidad de la Aplicación Móvil
        <span>“REGIÓN CONECTADA”</span> y de http://www.regiónconectada.com, lo
        cual el usuario declara conocer y brinda su aceptación y consentimiento,
        incluido los datos laborales que se le podrían solicitar, para futuras
        alternativas de trabajo que se le puedan ofrecer.
      </p>

      <h2>
        2. Servicios de la Aplicación Móvil “REGIÓN CONECTADA” y de su Portal
        WEB REGIÓN CONECTADA.COM.
      </h2>
      <p>
        Toda persona que sea autorizada podrá registrarse en la Aplicación Móvil
        <span>“REGIÓN CONECTADA”</span>, así deberá ingresar algunos datos
        básicos como su nombre, correo electrónico, documento de identificación,
        fecha de nacimiento, número de teléfono móvil, domicilio particular, u
        otros datos que se determinen. Esta Aplicación Móvil debe ser descargada
        por toda persona que quiera acceder a un Sistema de Seguridad preventiva
        en tiempo real, ya sea con familiares, amigos, vecinos, etc, o por
        profesionales del Centro de Atención de Ciudadanos que se implementara
        de forma paulatina para estos fines. Este último Servicio sólo estará
        disponible una vez que se dé a conocer a todo usuario a través de
        notificaciones que se enviarán a la Aplicación Móvil o sea publicado en
        el Portal WEB de <span>“REGIÓN CONECTADA”</span>. El usuario podrá
        descargar la Aplicación Móvil a su smartphone tanto en la tienda Google
        Play como en Apple Store, dependiendo del sistema operativo que tenga su
        smartphone y comenzará a disfrutar de las funciones desarrolladas en la
        Aplicación Móvil <span>“REGIÓN CONECTADA”</span>.
      </p>
      <p>
        Considerando que la Aplicación Móvil permite compartir la Cámara del
        propio smartphone o las Cámaras IP que todo usuario tenga instaladas en
        algún inmueble o lugar geográfico que éste determine, ésta acción, es
        decir, <span>“Compartir visualización de Cámaras”</span>, solo podrá
        hacerse siempre y cuando el usuario principal de su autorización expresa
        para la visualización de la Cámara de su smartphone, mediante un click
        que aparecerá al momento de Instalar la referida Aplicación Móvil. Por
        otra parte las Cámaras IP físicas que el usuario instale en algún
        inmueble o lugar geográfico que éste determine, podrán ser compartidas
        ya sea con familiares, vecinos u operadores del Centro de Atención
        Ciudadana, siempre y cuando el propio usuario principal así lo determine
        en la Configuración de cada Cámara IP, acción que podrá realizar o no en
        la propia Aplicación Móvil <span>“REGIÓN CONECTADA”</span>.
      </p>
      <p>
        A través de la Aplicación Móvil, toda persona que la tenga podrá
        contactar ante una emergencia a familiares, vecinos que pertenezcan a su
        Red de Seguridad Personal o a un profesional del Centro de Atención
        Ciudadana que se pretende desarrollar para brindar la referida atención
        de ciudadanos. Mayores detalles de los servicios que ofrece la
        Aplicación Móvil <span>“REGIÓN CONECTADA”</span>, se describen a
        continuación:
      </p>

      <h3>Descripción Detallada de la Aplicación Móvil “REGIÓN CONECTADA”.</h3>
      <p>
        La Plataforma <span>“REGIÓN CONECTADA”</span> cuenta con al menos dos
        Sistemas claramente identificables. El primero es el Aplicativo Móvil
        que permite realizar denuncias, tanto personales como de terceros, más
        la comunicación con el Sistema <span>“Click2Call”</span> o Call Center
        de Video Conferencia y el Segundo es el Panel WEB en donde se reciben
        las referidas denuncias y que incluso puede actuar de Call Center ya que
        tiene la particularidad de poder hacer una video conferencia inmediata
        con el denunciante a partir del propio Panel WEB.
      </p>
      <p>
        La Aplicación Móvil <span>“REGIÓN CONECTADA”</span> cuenta con siete
        elementos básicos de comunicación, los cuales son:
      </p>

      <ul>
        <li>Botón de Alerta de Seguridad o Alarma Personal.</li>
        <li>
          Sistema de Atención o Call Center de Video Conferencia, de nombre
          <span>“Click2Call”</span>.
        </li>
        <li>Sistema de Denuncias propiamente tal.</li>
        <li>
          Sistema complementario de Cámaras IP para ser instaladas en el
          interior del Hogar y ser vistas en el Aplicativo Móvil de
          <span>“REGIÓN CONECTADA”</span> por parte del usuario, incluso con la
          capacidad de ser compartidas con la Central de Atención si el usuario
          así lo estima y autoriza.
        </li>
        <li>
          Generación de una Red de Seguridad Personal, o asociatividad entre
          vecinos, familiares, etc.
        </li>
        <li>
          Panel WEB de comunicación total o Administración, que recepciona las
          denuncias realizadas y establece comunicaciones inmediatas por video
          conferencia con las víctimas, generando la geolocalización de éstas
          con mapeo de calor.
        </li>
        <li>
          Sistema de Noticias de la Región, como por ejemplo el Twitter del
          Gobierno Regional de Arica y Parinacota, etc.
        </li>
      </ul>

      <p>
        <span>Botón de Alerta de Seguridad:</span> Cada usuario de la Aplicación
        Móvil <span>“REGIÓN CONECTADA”</span> puede crear una Red de Seguridad
        Personal agregando hasta cinco personas ya sea, familiares, amigos y/o
        vecinos. De esta forma, ante el evento indeseable de una emergencia,
        éste puede activar el Botón de <span>“Alerta de Seguridad”</span>, así
        el sistema notificará de forma inmediata a nuestro Centro de Atención
        Ciudadana, como así también, a la Red de Seguridad Personal
        anteriormente creada. Esta notificación se envía con la ubicación desde
        donde se generó la alerta y cualquier usuario de la Red de Seguridad
        Personal o algún operador de nuestro Centro de Atención Ciudadana podrá
        intentar establecer una video llamada con el usuario que activó la
        Alerta o Contactarlo por una llamada telefónica.
      </p>
      <p>
        <span>El Sistema “Click2Call”</span>: Esta Aplicación Móvil permite la
        comunicación en forma inmediata con este Sistema o Centro de Atención
        Ciudadana solo por vía video conferencia o video mail
      </p>
      <p>
        <span>Todas las Denuncias:</span> Se hacen a partir del Aplicativo Móvil
        el cual da la posibilidad de una variada gama de denuncias de los
        diferentes delitos e incivilidades que se comenten en la Región. Así una
        vez que el usuario define cual es el delito o incivilidad a denunciar,
        automáticamente el Sistema toma la cámara externa del smartphone y
        comienza a grabar, así esta grabación llega en forma inmediata el Panel
        WEB o de Administración del Sistema permitiendo visualizar la denuncia a
        través de la geolocalización del Móvil del denunciante, con el fin de
        establecer la ubicación del delito o incivilidad denunciada.
      </p>
      <p>
        <span
          >Instalación de Cámaras IP con audio disuasivo y bidireccional:</span
        >
        Estas Cámaras podrán ser instaladas en el interior del hogar y podrán
        ser vistas por el usuario en el mismo Aplicativo Móvil de Región
        Conectada en todo momento. Incluso estas Cámaras traen la posibilidad de
        ser compartidas con el Centro de Atención Ciudadana y también tienen la
        posibilidad de generar alarmas por movimiento.
      </p>
      <p>
        <span>Generación de una Red de Seguridad Personal:</span> Todo usuario
        que descargue el Aplicativo Móvil <span>“REGIÓN CONECTADA”</span> podrá
        invitar a ser parte de su Red Personal de Seguridad a un máximo de 5
        personas, ya sea familiares, amigos o vecinos.
      </p>
      <p>
        <span>Panel WEB de “REGIÓN CONECTADA”:</span> En el Panel WEB del
        Sistema se registrarán las denuncias que han ido ingresando a través de
        la Aplicación Móvil. Este Panel WEB cuenta con mapas de calor, detalle
        de las denuncias realizadas, atención de las denuncias en tiempo real
        por un funcionario de nuestra organización.
      </p>
      <p>
        <span>Un Sistema de Noticias:</span> Este puede ser ejecutado
        directamente por parte de nuestra Organización, o bien, utilizar por
        ejemplo el Twitter del Gobierno Regional de Arica y Parinacota siempre y
        cuando este Organismo así lo permita.
      </p>
      <p>
        <span
          >Pantallas Principales de la Aplicación Móvil de “REGIÓN
          CONECTADA”.</span
        >
      </p>

      <div class="container-img">
        <img
          src="../../assets/images/Screenshot_1.jpg"
          alt="App móvil Región Conectada"
        />
        <img
          src="../../assets/images/Screenshot_2.jpg"
          alt="App móvil Región Conectada"
        />
      </div>

      <h2>3. Usos Prohibidos de la Aplicación Móvil y de su Portal WEB.</h2>

      <p>
        <span
          >El Usuario de la Aplicación Móvil “REGIÓN CONECTADA” no podrá:</span
        >
      </p>
      <ul>
        <li>
          Usar ningún robot, araña (spider), otros aparatos automáticos o
          procesos manuales para monitorear o copiar la Aplicación Móvil
          <span>“REGIÓN CONECTADA”</span> y el Portal WEB
          http://www.regiónconectada.com, o la información y materiales
          contenidas tanto en la Aplicación Móvil como en el Sitio WEB, sin el
          previo consentimiento otorgado por escrito por parte de
          <span>NATURALPHONE S.A.</span>
        </li>
        <li>
          Usar cualquier aparato, software o rutina para interferir o intentar
          interferir con la operación correcta y normal de la Aplicación Móvil
          <span>“REGIÓN CONECTADA”</span> y de su Sitio WEB, o con cualquier
          transacción realizada a través de Sub Portales que contengan o puedan
          contener el Sitio WEB http://www.regiónconectada.com.
        </li>
        <li>
          Reproducir, transmitir, distribuir, exhibir, presentar públicamente
          cualquier información y/o material contenido tanto en la Aplicación
          Móvil <span>“REGIÓN CONECTADA”</span> y en su Sitio WEB (a excepción
          de la información que el usuario suministre) sin el previo
          consentimiento otorgado por escrito por
          <span>NATURALPHONE S.A.</span>, y/o el consentimiento de cualquier
          tercera parte pertinente.
        </li>
        <li>
          Retirar, manipular o utilizar los símbolos, señas y señales protegidos
          por las normas de derechos de autor, marca registrada o cualquier otro
          aviso o publicación que se encuentre patentado y que se encuentren
          incorporados en la Aplicación Móvil <span>“REGIÓN CONECTADA”</span> y
          en su Sitio WEB.
        </li>
        <li>
          Revertir ingeniería, revertir recopilación, revertir montaje o de otra
          forma intentar revelar o duplicar para cualquier propósito la
          metodología, el know-how o los secretos comerciales que sostienen la
          Aplicación Móvil <span>“REGIÓN CONECTADA”</span> y de su Sitio WEB.
        </li>
      </ul>

      <h2>4. Suspensión o Cancelación de la Cuenta del Usuario.</h2>
      <p>
        <span>NATURALPHONE S.A.</span>, podrá emitir una advertencia, suspender
        temporalmente, suspender indefinidamente o cancelar la cuenta del
        usuario por cualquier falta que éste cometa, si es que éste hubiera
        utilizado la Aplicación Móvil “REGIÓN CONECTADA” generando transmisiones
        en vivo, videos o comentarios que son reñidos con la moral de las
        personas, o bien, que éste haya abierto un registro en el Portal WEB
        http://www.regiónconectada.com o en algún sub portal de la empresa, si
        considera, que el usuario:
      </p>
      <ul>
        <li>
          Ha violado los Términos y Condiciones o ha actuado en violación del
          presente Acuerdo.
        </li>
        <li>
          La información suministrada por el usuario es incorrecta o no puede
          ser verificada.
        </li>
        <li>
          Sí <span>NATURALPHONE S.A.</span>, considera que las acciones del
          usuario pueden acarrear responsabilidad legal del propio usuario y de
          otros usuarios o de <span>“REGIÓN CONECTADA”</span>.
        </li>
      </ul>
      <p>
        El usuario principal acepta y reconoce que el uso de la Aplicación Móvil
        <span>“REGIÓN CONECTADA”</span> y de su Sitio WEB será bajo su propia
        responsabilidad y riesgo.
      </p>
      <p><span>NATURALPHONE S.A., no garantiza:</span></p>

      <ul>
        <li>
          Que el servicio será continuo, ininterrumpido, oportuno, seguro y/o
          libre de error.
        </li>
        <li>La calidad, identidad o fiabilidad de sus usuarios.</li>
        <li>La veracidad de la información suministrada por los usuarios.</li>
        <li>
          La calidad de cualquier material bajado (download) o de cualquier
          forma obtenida a través de la Aplicación Móvil, o bien, del Portal WEB
          http://www.regiónconectada.com.
        </li>
        <li>
          <span>NATURALPHONE S.A.</span>, no será responsable por los daños que
          puedan ocasionar los materiales obtenidos a través de dichos medios,
          al igual que no se hace responsable por cualquier situación de delito
          no resuelta satisfactoriamente que el usuario tenga o que pueda tener
          en el futuro, dado que es una Aplicación Móvil de prevención y
          comunicación entre distintos usuarios e Instituciones.
        </li>
      </ul>

      <h2>5. Ley Aplicable y Jurisdicción.</h2>
      <p>
        Cualquier controversia que surja entre <span>NATURALPHONE S.A.</span>, y el o los
        usuarios por causa o con ocasión del presente Acuerdo y que no pueda ser
        resuelta amigablemente por las partes, será resuelta por un Tribunal de
        Arbitraje, con sede en la ciudad de Santiago de Chile, compuesto por un
        (1) árbitro, designado por la Cámara de Comercio de Santiago, de acuerdo
        con las normas de dicho centro, a la cual las partes se someten.
      </p>
      <p>Para conocer detalles sobre la protección de datos ver: <span>Políticas de Privacidad.</span></p>
    </div>
  </main>
</template>

<script>
import Header from "../commons/Header.vue";

export default {
  components: {
    Header,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
h1,
h2,
h3 {
  color: #000;
}
span {
  font-weight: 800;
}
.terminos-container {
  width: 90vw;
  margin-top: 8vh;
  margin-bottom: 0vh;
  padding-right: 10vw;
  padding-left: 10vw;
  text-align: justify;
}
h1 {
  font-size: 1.2rem;
}
h2 {
  font-size: 1rem;
}
h3 {
  font-size: 0.9rem;
}

.bg-navbar {
  background-color: #ec830b;
  height: 180px;
}

.container-img {
    display: flex;
    gap: 100px;
}
img {
    width: 300px;
}
@media (max-width: 580px) {
    .container-img {
        flex-direction: column;
        gap: 50px;
    }
}
</style>
