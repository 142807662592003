<template>
    <div>
    <main style="background: transparent linear-gradient(219deg, #FFCE00 0%, #FFEC98 31%, #F1C407 66%, #E3710F 100%) 0% 0% no-repeat padding-box; height: 100vh;">
        <div style="display: flex; align-items: center;">
            <img style="width: 50px;" src="../../assets/images/logo-region.png" alt="">
            <h1 style="color: white; font-size: 24px; text-transform: uppercase; margin-bottom: 0; font-weight: 400;">región conectada</h1>
        </div>

<!--         <div class="info-wheel">
            <h2 style="color:#000; text-transform:uppercase; font-size: 18px;">¡felicidades!</h2>
            <p>Haz ganado la oportunidad de participar en el beta privado de Región Conectada además puedes ganar una cámara IP u obtener un gran descuento.</p>
        </div> -->

        <section class="roulette-container mt-10">
            <div id="selector"></div>
            <div style="
            -webkit-box-shadow: 4px 33px 55px -11px rgba(0,0,0,0.75);
            -moz-box-shadow: 4px 33px 55px -11px rgba(0,0,0,0.75);
            box-shadow: 4px 33px 55px -11px rgba(0,0,0,0.75);
            border: double 6px transparent;
            background-image: linear-gradient(#000, #000), linear-gradient(to right, #91398B, #FDDB31);
            border-image-slice: 1;
            border-radius: 100%;
            background-origin: border-box;
            background-clip: content-box, border-box;">
            <div style="border: 16px solid black; border-radius: 100%">

            <div id="roulette" style="position:relative;">
                <div v-for="item in items" :key="item.id"  class="roulette-section">
                    <div class="roulette-section-container">
                        <!--<p style="color: #FFF; transform: rotate(-90deg) translate(-24px); font-size: 24px;">{{item.uid}}</p> -->
                        <img class="prize" v-show="item.uid" v-if="item.uid !== 'new_spin'" :src="item.image_url" alt="Premio">
                        <p class="prize-text" v-show="item.uid" style="color: #FFF;   font-weight: 700;" v-else>Nuevo Giro</p>
                    </div>
                </div>
                <div class="center-circle"></div>
            </div>
            </div>
            </div>
            <div class="button-container">
                <button :disabled="disabledbtn" class="btn" type="button" @click="getResult">GIRAR PARA GANAR</button>
              <!--   <button type="button" id="reset">Resetear</button> -->
            </div>
        </section>
    </main>

    <v-dialog v-model="dialog" width="300" persistent style="margin:0;">
        <v-card style=" max-height: 550px; background: transparent linear-gradient(0deg, #FFCE00 0%, #FFEC98 31%, #F1C407 66%, #E3710F 100%) 0% 0% no-repeat padding-box; padding: 20px; border-radius:29px;box-shadow: 0px 13px 46px #00000029;">
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-between; max-height: 500px;">

            <div>
                <h2 style="text-align:center;">¡Felicidades!</h2>
                <p v-if="prize.uid == 'camera'" style="text-align:center; color: #FFF;">Haz ganado una cámara Ip SOSCAM. Ahora solo debes descargar el app Región Conectada</p>
                <p v-if="prize.uid == 'opportunities'" style="text-align:center; color: #FFF;">Haz ganado {{prize.amount}} {{prize.amount !== 1 ? 'oportunidades' : 'oportunidad'}} para el sorteo de cámaras SOSCAM. Tienes {{acc_opportunities}} {{acc_opportunities !== 1 ? 'oportunidades' : 'oportunidad'}} {{acc_opportunities !== 1 ? 'acumuladas' : 'acumulada'}} .</p>
                <p v-if="prize.uid == 'new_spin'" style="font-size: 16px; text-align:center; color: #FFF;">Haz ganado un nuevo giro</p>
            </div>

            <img v-if="prize.uid !== 'new_spin'" style="width: 200px;" :src="prize.image_url" alt="Premio">

          <v-spacer></v-spacer>
          <div class="button-container">
          <button
            style="display:flex; margin: 0 auto;"
            class="btn"
            @click="items.status ? $router.push('/') : dialog = false;"
          >
            {{items.status ?  'IR AL INICIO' : 'VOLVER A GIRAR'}}
          </button>
          </div>
        </div>
      </v-card>
      </v-dialog>

      <v-dialog v-model="dialogNoAttempts" width="300" persistent>
        <v-card style="display:flex; align-items:center; justify-content: center; flex-direction: column; height: 150px; background: transparent linear-gradient(0deg, #FFCE00 0%, #FFEC98 31%, #F1C407 66%, #E3710F 100%) 0% 0% no-repeat padding-box; padding: 20px; border-radius:29px;box-shadow: 0px 13px 46px #00000029;">
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-between; ">
                <p style="font-size: 32px;text-align: center;">No te quedan  intentos!</p>
                <button
            style="display:flex; margin: 0 auto;"
            class="btn"
            @click="$router.push('/')"
          >
            IR AL INICIO
          </button>
            </div>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import { getRouletteOptions, getRouletteResult } from "@/helpers/api/sosdenuncias/sosdenuncias";
export default {
    data () {
        return {
        items: ["","","","","","","","","",""],
        result: {},
        index: 0,
        rotate: 0,
        lanzamiento: false,
        prize: {},
        dialog: false,
        disabledbtn: false,
        dialogNoAttempts: false
}
    },
    methods: {
        getOptions() {
            getRouletteOptions().then(res => {
                this.items = res.options ? res.options : res.status;

                if (this.items == "no_attempts_left") {
                    this.dialogNoAttempts = true;
                }
            })
        },
        getResult() {
            this.disabledbtn = true;
            getRouletteResult().then(res => {
            this.result = res.result;
            if (this.result.uid == 'camera') {
                this.index = this.items.map(e => e.uid).indexOf('camera');
            } else if (this.result.uid == 'new_spin') {
                this.index = this.items.map(e => e.uid).indexOf('new_spin');
            } else if (this.result.uid == 'opportunities') {
                this.index = this.items.findIndex(e => e.uid == 'opportunities' && e.amount == this.result.amount)
                this.acc_opportunities = res.offer.offer_data.amount
            }

            setTimeout(() => {
                var audio = new Audio(require('../../assets/sounds/roulette.mp3'))
                audio.play()
            }, 170);

            this.rotate = ((this.index * 36 + 18) * -1) - 1080

            roulette.style.transform = `rotate(${this.rotate}deg)`;
            roulette.style.transitionDuration = `5s`;

            this.prize = this.items[this.index]

            }).finally(() => {
                setTimeout(() => {
                    this.dialog = true;
                    roulette.style.transform = "rotate(0deg)";
                    roulette.style.transitionDuration = ".5s";
                    this.disabledbtn = false;
                    this.getOptions()
                }, 6000);
            })
        },
    },
    mounted () {
    const logged = localStorage.getItem("userTokenSOSDenuncias") ? true : false
    logged ? this.getOptions() : this.$router.push({ path: '/login', query: { redirect_to: this.$route.query.redirect_to } })
    },
}
</script>

<style lang="scss" scoped>
// generals
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: #1b1b1b;
    font-family: Arial, Helvetica, sans-serif;
}

.button-container {
    display: flex;
    justify-content: center;
}

.button-container button {
    border: none;
    outline: none;
    padding: 15px 32px;
    color: #FFF;
    background: transparent linear-gradient(160deg, #FF6200 0%, #C71D24 100%) 0% 0% no-repeat padding-box;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 17px;
}

.button-container button:hover {
    background: transparent linear-gradient(160deg, #d65402 0%, #aa181d 100%) 0% 0% no-repeat padding-box;
}

.button-container button:active {
    background: transparent linear-gradient(160deg, #bb4902 0%, #aa171c 100%) 0% 0% no-repeat padding-box;
}


.button-container button:disabled {
  background: #E5E5E5;
color: #B0B0B0;
cursor: default;
}

img {
    width: 62px;
}
.prize-text {
    transform: rotate(-90deg) translate(-24px);
    font-size: 20px;
}

@media (max-width: 568px) {
    .prize {
        transform: rotate(-15deg);
    }
    .prize-text {
        transform: rotate(-95deg);
        font-size: 14px;
    }
        img {
            width: 46px;
        }
}

//roulette
html,
body,
main {
    height: 100%;
}

body main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
}

@media (max-width: 568px) {
    body main {
        padding-top: 40px;
        justify-content: flex-start;
    }
    
}

main .roulette-container {
    position: relative;
}

main .roulette-container #selector {
    position: absolute;
    top: -20px;
    left: 50%;
    display: inline-block;
    width: 0%;
    height: 0%;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-top: 50px solid #f2f2f2;
    z-index: 1;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

#roulette {
    background: #fff;
    width: 316px;
    height: 316px;
    position: relative;
    font-size: 14px;
    overflow: hidden;
    border-radius: 100%;
    transition-property: transform;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
        border: double 6px transparent;
    background-image: linear-gradient(#000, #000), linear-gradient(to right, #FDDB31, #91398B);
    border-image-slice: 1;
        background-origin: border-box;
    background-clip: content-box, border-box;

}

@media (min-width: 685px) {
    #roulette {
        width: 450px;
        height: 450px;
    }

}

#roulette .roulette-section {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    width: 50%;
    height: 50%;
    transform-origin: 0% 100%;
}

#roulette .roulette-section .roulette-section-container {
    position: absolute;
    left: -100%;
    top: -30px;
    width: 200%;
    height: 200%;
    text-align: center;
    padding-top: 60px;
    transform: skewY(deg) rotate(24deg);
    -webkit-transform: skewY(54deg) rotate(24deg);
    -moz-transform: skewY(54deg) rotate(24deg);
    -ms-transform: skewY(54deg) rotate(24deg);
    -o-transform: skewY(54deg) rotate(24deg);
}

@media (max-width: 568px) {
    #roulette .roulette-section .roulette-section-container {
    top: -40px;
    transform: skewY(deg) rotate(30deg);
    -webkit-transform: skewY(54deg) rotate(30deg);
    -moz-transform: skewY(54deg) rotate(30deg);
    -ms-transform: skewY(54deg) rotate(30deg);
    -o-transform: skewY(54deg) rotate(30deg);
    }
}

/* #roulette .roulette-section .roulette-section-container p {
    margin-top: 30px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
} */

.button-container {
    margin-top: 20px;
}

.button-container button#reset {
    display: none;
}

//rotations
#roulette .roulette-section:first-child {
    transform: rotate(0deg) skewY(-54deg);
    -webkit-transform: rotate(0deg) skewY(-54deg);
    -moz-transform: rotate(0deg) skewY(-54deg);
    -ms-transform: rotate(0deg) skewY(-54deg);
    -o-transform: rotate(0deg) skewY(-54deg);
}

#roulette .roulette-section:nth-child(2) {
    transform: rotate(36deg) skewY(-54deg);
    -webkit-transform: rotate(36deg) skewY(-54deg);
    -moz-transform: rotate(36deg) skewY(-54deg);
    -ms-transform: rotate(36deg) skewY(-54deg);
    -o-transform: rotate(36deg) skewY(-54deg);
}

#roulette .roulette-section:nth-child(3) {
    transform: rotate(72deg) skewY(-54deg);
    -webkit-transform: rotate(72deg) skewY(-54deg);
    -moz-transform: rotate(72deg) skewY(-54deg);
    -ms-transform: rotate(72deg) skewY(-54deg);
    -o-transform: rotate(72deg) skewY(-54deg);
}

#roulette .roulette-section:nth-child(4) {
    transform: rotate(108deg) skewY(-54deg);
    -webkit-transform: rotate(108deg) skewY(-54deg);
    -moz-transform: rotate(108deg) skewY(-54deg);
    -ms-transform: rotate(108deg) skewY(-54deg);
    -o-transform: rotate(108deg) skewY(-54deg);
}

#roulette .roulette-section:nth-child(5) {
    transform: rotate(144deg) skewY(-54deg);
    -webkit-transform: rotate(144deg) skewY(-54deg);
    -moz-transform: rotate(144deg) skewY(-54deg);
    -ms-transform: rotate(144deg) skewY(-54deg);
    -o-transform: rotate(144deg) skewY(-54deg);
}

#roulette .roulette-section:nth-child(6) {
    transform: rotate(180deg) skewY(-54deg);
    -webkit-transform: rotate(180deg) skewY(-54deg);
    -moz-transform: rotate(180deg) skewY(-54deg);
    -ms-transform: rotate(180deg) skewY(-54deg);
    -o-transform: rotate(180deg) skewY(-54deg);
}

#roulette .roulette-section:nth-child(7) {
    transform: rotate(216deg) skewY(-54deg);
    -webkit-transform: rotate(216deg) skewY(-54deg);
    -moz-transform: rotate(216deg) skewY(-54deg);
    -ms-transform: rotate(216deg) skewY(-54deg);
    -o-transform: rotate(216deg) skewY(-54deg);
}

#roulette .roulette-section:nth-child(8) {
    transform: rotate(252deg) skewY(-54deg);
    -webkit-transform: rotate(252deg) skewY(-54deg);
    -moz-transform: rotate(252deg) skewY(-54deg);
    -ms-transform: rotate(252deg) skewY(-54deg);
    -o-transform: rotate(252deg) skewY(-54deg);
}

#roulette .roulette-section:nth-child(9) {
    transform: rotate(288deg) skewY(-54deg);
    -webkit-transform: rotate(288deg) skewY(-54deg);
    -moz-transform: rotate(288deg) skewY(-54deg);
    -ms-transform: rotate(288deg) skewY(-54deg);
    -o-transform: rotate(288deg) skewY(-54deg);
}

#roulette .roulette-section:nth-child(10) {
    transform: rotate(324deg) skewY(-54deg);
    -webkit-transform: rotate(324deg) skewY(-54deg);
    -moz-transform: rotate(324deg) skewY(-54deg);
    -ms-transform: rotate(324deg) skewY(-54deg);
    -o-transform: rotate(324deg) skewY(-54deg);
}


    //colors
    #roulette .roulette-section:first-child .roulette-section-container {
    background: linear-gradient(#E30C69, #ffffff) ; 
}
#roulette .roulette-section:nth-child(2) .roulette-section-container {
	background: linear-gradient(#8d078d, #ffffff) ; 
}
#roulette .roulette-section:nth-child(3) .roulette-section-container {
	background: linear-gradient(#5C0FF3, #ffffff) ;
}
#roulette .roulette-section:nth-child(4) .roulette-section-container {
	background: linear-gradient(#23C4FF, #ffffff) ;
}
#roulette .roulette-section:nth-child(5) .roulette-section-container {
	background: linear-gradient(#23bb23, #ffffff) ;
}
#roulette .roulette-section:nth-child(6) .roulette-section-container {
	background: linear-gradient(#009E85, #ffffff) ;
}
#roulette .roulette-section:nth-child(7) .roulette-section-container {
    background: linear-gradient(#88c740, #ffffff) ;
}
#roulette .roulette-section:nth-child(8) .roulette-section-container {
        background: linear-gradient(#f2f215, #ffffff) ;
}
#roulette .roulette-section:nth-child(9) .roulette-section-container {
        background: linear-gradient(#FEC017, #ffffff) ;
}
#roulette .roulette-section:nth-child(10) .roulette-section-container {
    background: linear-gradient(#FE9815, #ffffff) ;
}

.center-circle  {
    width: 80px;
    height: 80px;
    background-color: #000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border: double 6px transparent;
    background-image: linear-gradient(#000, #000), linear-gradient(to right, #FDDB31, #91398B);
    border-image-slice: 1;
    border-radius: 100%;
    background-origin: border-box;
    background-clip: content-box, border-box;
    -webkit-box-shadow: 2px 5px 48px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 5px 48px -11px rgba(0,0,0,0.75);
    box-shadow: 2px 5px 48px -11px rgba(0,0,0,0.75);    
}

.info-wheel {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 29px; padding:20px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
}

</style>