import request from "./requestSOSDenuncias";
import { endpoints } from "../endpoints";

export function login(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "auth/login",
      method: "post",
      data,
    })
      .then(async function (response) {
        if (response && response.access_token) {
          localStorage.setItem("userTokenSOSDenuncias", response.access_token);
        }
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function logout(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "auth/logout",
      method: "post",
      data,
    })
      .then(async function (response) {
        localStorage.removeItem("userTokenSOSDenuncias");
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function signUp(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "auth/signup",
      method: "post",
      data,
    })
      .then(async function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function getInvitingUserInfo(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "user/getUserInfoByCode",
      method: "get",
      params: data,
    })
      .then(async function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function enterToNetworkWithInvitationCode(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "networks/enterToNetworkWithInvitationCode",
      method: "post",
      data,
    })
      .then(async function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function passwordResetRequest(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "auth/resetpassrequest",
      method: "post",
      data,
    })
      .then(async function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function checkTokenPasswordReset(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "auth/checktoken",
      method: "post",
      data,
    })
      .then(async function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function passwordReset(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "auth/resetpass",
      method: "post",
      data,
    })
      .then(async function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function deleteAccount() {
  return new Promise((resolve, reject) => {
    request({
      url: "/user/deleteUserAccount",
      method: "post",
    })
      .then(async function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getAccountRecoveryData(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "/notifications/getAccountRecoveryData",
      method: "get",
      params: data,
    })
      .then(async function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function accountRecovery(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "/auth/accountRecovery",
      method: "post",
      data,
    })
      .then(async function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function revokeAllAccess() {
  return new Promise((resolve, reject) => {
    request({
      url: "/auth/revokeAllAccess",
      method: "delete",
    })
      .then(async function (response) {
        localStorage.removeItem("userTokenSOSDenuncias");
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function accountResetRequest() {
  return new Promise((resolve, reject) => {
    request({
      url: "/auth/accountResetRequest",
      method: "post",
    })
      .then(async function (response) {
        localStorage.removeItem("userTokenSOSDenuncias");
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 2FA

export function startVerifyUserPhoneNumber(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "/auth/2fa/startVerifyUserPhoneNumber",
      method: "post",
	  data
    })
      .then(async function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function verifyUserPhoneNumber(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "/auth/2fa/verifyUserPhoneNumber",
      method: "post",
	  data
    })
      .then(async function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function requestTwoFactorAuthCode(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "/auth/2fa/requestTwoFactorAuthCode",
      method: "post",
	  data
    })
      .then(async function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function confirmTwoFACodeLogin(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "/auth/2fa/confirmTwoFACodeLogin",
      method: "post",
	  data
    })
      .then(async function (response) {
		if (response && response.access_token) {
			localStorage.setItem("userTokenSOSDenuncias", response.access_token);
		  }
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getUserConfirmedPhones(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "/auth/2fa/getUserConfirmedPhones",
      method: "get",
	  data
    })
      .then(async function (response) {
		if (response && response.access_token) {
			localStorage.setItem("userTokenSOSDenuncias", response.access_token);
		  }
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Config

/* USER CONFIG */
export function GetLoggedUserInfo() {
  return new Promise((resolve) => {
    request({
      url: "user/info",
      method: "get",
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

export function updateUserInfo(data) {
  return new Promise((resolve) => {
    request({
      url: "user/update",
      method: "post",
      data,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

/* DEVICES CONFIG */
export function getUserDevices() {
  return new Promise((resolve) => {
    request({
      url: "devices/getUserDevices",
      method: "get",
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

export function updateDeviceConfig(data) {
  return new Promise((resolve) => {
    request({
      url: "devices/updateDeviceConfig",
      method: "post",
      data,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

/* Networks Config */
export function getMyNetwork() {
  return new Promise((resolve) => {
    request({
      url: "networks/getMyNetwork",
      method: "get",
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

export function addUserToNetwork(data) {
  return new Promise((resolve) => {
    request({
      url: "/networks/addUserToNetwork",
      method: "post",
      data,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

export function quitSecurityNetwork(data) {
  return new Promise((resolve) => {
    request({
      url: "/networks/quitSecurityNetwork",
      method: "post",
      data,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

export function acceptRejectInvitation(data) {
  return new Promise((resolve) => {
    request({
      url: "/networks/acceptRejectInvitation",
      method: "post",
      data,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

// Wheel

export function getRouletteOptions(data) {
  return new Promise((resolve) => {
    request({
      url: "/marketing/getRouletteOptions",
      method: "get",
      params: data,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

export function getRouletteResult(data) {
  return new Promise((resolve) => {
    request({
      url: "/marketing/getRouletteResult",
      method: "get",
      params: data,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}
