<template>
  <div>
    <div
      data-w-id="4e969de5-dbb7-4e4a-e205-2edd565b379b"
      style="opacity: 1"
      class="section center wf-section"
    >
      <img src="@/assets/images/arrow.svg" alt="" class="arrow" />
      <div
        data-w-id="6b220cb1-4b9c-7fb0-75b9-53ff4a6c35a2"
        style="
          -webkit-transform: translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          -moz-transform: translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          -ms-transform: translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          transform: translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          opacity: 1;
        "
        class="hero-content"
      >
        <h1
          data-w-id="f8a3a51b-0bd7-225c-c6a0-97acfcbbd7ee"
          style="opacity: 1; margin-bottom: 0;font-size: 32px;"
          class=" hero-title"
        >
          Región Conectada
        </h1>

        <h5
          style="opacity: 1; color: #FFF;font-size: 20px; text-align: justify; line-height: 1.2;"
        >
          <strong>
            Proyecto “Levantamiento Información Situación Delictual e
            Incivilidades de Región Arica y Parinacota”, Código BIP: 40045972-0
            es financiado con fondos del Gobierno Regional de Arica y
            Parinacota.</strong
          >
        </h5>

        <h3
          data-w-id="f4820783-e165-dba0-a4bc-61fedcc80587"
          style="opacity: 1; margin-top: 0;"
          class="hero-subhead _2"
        >
          <strong
            >¡Lucha contra la delincuencia en tu región con Región
            Conectada!</strong
          >
          Nuestra plataforma te ofrece la oportunidad de denunciar delitos y
          comunicarte con nuestro sistema de atención “Click2Call” a través de
          nuestra Aplicación Móvil, la cual tienes que descargar en tu
          smartphone. Todas las denuncias se envían al Panel WEB para ser
          localizadas. Esta Aplicación Móvil además, cuenta con un Botón de
          Emergencia y Cámaras para protegerte.
          <strong
            >¡Únete a nosotros y conviértete en un protector de tu
            región!</strong
          >
        </h3>
        <div>
          <div class="container-descarga">
            <router-link
              :to="{ path: '/ganadores-camaras' }"
              style="color: #1a1b1f; font-size: 12px"
            >
              <div class="heroConcourseWrapper">
                <img
                  class="heroConcourseWrapper__Img"
                  src="../../assets/soscam.png"
                  alt=""
                />
                <p class="heroConcourseWrapper__Text">
                  Ganadores Sorteo de Cámaras
                </p>
              </div>
            </router-link>
            <!--         <h3 style="color:#FFF; margin-right: 8px;">Descarga la App</h3> -->
            <div class="container-descargabtns">
              <a
                href="https://apps.apple.com/us/app/regi%C3%B3n-conectada/id6449751083"
              >
                <img
                  class="apple"
                  style="width: 90%; margin: 0 aut0"
                  src="../../assets/images/appstore.png"
                  alt=""
                />
              </a>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.naturalphone.regionconectada"
                >
                  <img src="../../assets/images/googleplay.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <a
          href="https://regionconectada.com/registrate"
          style="
            margin-top: 8px;
            cursor: pointer;
            border-radius: 5px;
            padding: 5px;
            color: #fff;
            font-size: 20px;
            background: #bf4e01;
            display: inline-block;
            margin-bottom: 12px;
          "
          >Regístrate, Descarga, Instala y participa por una de las 50
          cámaras!</a>
      </div>
      <div
        data-delay="4000"
        data-animation="slide"
        class="slider w-slider"
        data-autoplay="true"
        data-easing="ease"
        data-hide-arrows="false"
        data-disable-swipe="true"
        data-autoplay-limit="0"
        data-nav-spacing="3"
        data-duration="500"
        data-infinite="true"
      >
        <div class="w-slider-mask">
          <div class="slide w-slide"></div>
          <div class="slide-2 w-slide"></div>
          <div class="slide-3 w-slide"></div>
          <div class="slide-4 w-slide"></div>
        </div>
        <!--         <div class="w-slider-arrow-left">
          <div class="w-icon-slider-left"></div>
        </div>
        <div class="right-arrow-2 w-slider-arrow-right">
          <div class="w-icon-slider-right"></div>
        </div> -->
        <!--   <div class="w-slider-nav w-slider-nav-invert w-round"></div> -->
      </div>
      <div class="div-block-16"></div>
    </div>
    <div id="App" class="section wf-section">
      <div id="features" class="feature-wrap">
        <div
          data-w-id="4593c4b7-56e8-060a-be57-648b0e08fc65"
          style="
            -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            opacity: 1;
          "
          class="feature-content"
        >
          <div
            data-w-id="4593c4b7-56e8-060a-be57-648b0e08fc66"
            style="opacity: 1"
            class="title"
          >
            Región Conectada
          </div>
          <h2
            data-w-id="b5c18a17-89b5-0e4d-1546-72a605699114"
            style="opacity: 1"
            class="blacktext"
          >
            App Móvil
          </h2>
          <p
            data-w-id="4593c4b7-56e8-060a-be57-648b0e08fc6c"
            style="opacity: 1"
            class="paragraph-2"
          >
            Permite a los usuarios realizar denuncias de delitos e incivilidades
            y comunicarse con el sistema “Click2Call” a través de video
            conferencias o video mails. El sistema “Click2Call” es un call
            center de atención por video conferencias que permite a los usuarios
            de la Aplicación Móvil, comunicarse cara a cara con los operadores y
            recibir atención de manera inmediata. Además, el aplicativo también
            cuenta con un módulo de atención que permite a los usuarios
            contactar a los operadores y un módulo de noticias que proporciona
            información actualizada sobre la región.
          </p>
          <h3
            data-w-id="3be32b1e-56af-bf8b-505b-406cb36197d2"
            style="opacity: 1"
          >
            Región Conectada cuenta con siete elementos básicos de comunicación,
            los cuales son:
          </h3>
        </div>
        <div class="feature-image">
          <img
            src="@/assets/images/Phone-5.png"
            width="666"
            style="opacity: 1"
            data-w-id="ca7ee6f6-0cc4-1040-5449-30cf20e4419e"
            sizes="(max-width: 479px) 90vw, (max-width: 991px) 449.9999694824219px, 45vw"
            alt=""
            class="image-2"
          />
        </div>
      </div>

      <div class="feature-wrap icons numbers">
        <div class="feature-content icons numbers">
          <h1 class="blacktext number-h1 pa-0 ma-0 mb-2">1</h1>
          <p
            data-w-id="9b3d29e2-b793-f300-069f-aad198e15c8a"
            style="opacity: 1"
            class="paragraph-3"
          >
            Botón de Alerta, notificará a la Red propia ante una emergencia.
          </p>
        </div>
        <div class="div-block-13"></div>
        <div class="feature-content icons numbers">
          <h1 class="blacktext number-h1 pa-0 ma-0 mb-2">2</h1>
          <p
            data-w-id="7545d0e6-4b58-3ff6-31b2-8f5a9e00498b"
            style="opacity: 1"
            class="paragraph-4"
          >
            Sistema de Atención o Call Center de Video Conferencia de nombre
            “Click2Call”.
          </p>
        </div>
        <div class="div-block-13"></div>
        <div class="feature-content icons numbers">
          <h1 class="blacktext number-h1 pa-0 ma-0 mb-2">3</h1>
          <p
            data-w-id="2cd02545-1c5c-726e-48c7-63bed45971c8"
            style="opacity: 1"
            class="paragraph-5"
          >
            Sistema de Denuncias propiamente tal.
          </p>
        </div>
        <div class="div-block-13"></div>
        <div class="feature-content icons numbers">
          <h1 class="blacktext number-h1 pa-0 ma-0 mb-2">4</h1>
          <p
            data-w-id="2a2b3181-a3c5-152c-2582-9c4aa29bf970"
            style="opacity: 1"
            class="paragraph-6"
          >
            Panel WEB de comunicación total o Administración que recepciona las
            denuncias realizadas.
          </p>
        </div>
        <div class="div-block-13"></div>
        <div class="feature-content icons numbers">
          <h1 class="blacktext number-h1 pa-0 ma-0 mb-2">5</h1>

          <p
            data-w-id="2a2b3181-a3c5-152c-2582-9c4aa29bf970"
            style="opacity: 1"
            class="paragraph-6"
          >
            Cámara WIFI de visualización del interior del hogar través de la
            Aplicación “Región Conectada”.
          </p>
        </div>
        <div class="div-block-13"></div>
        <div class="feature-content icons numbers">
          <h1 class="blacktext number-h1 pa-0 ma-0 mb-2">6</h1>

          <p
            data-w-id="2a2b3181-a3c5-152c-2582-9c4aa29bf970"
            style="opacity: 1"
            class="paragraph-6"
          >
            Red de Seguridad Personal.
          </p>
        </div>
        <div class="div-block-13"></div>
        <div class="feature-content icons numbers">
          <h1 class="blacktext number-h1 pa-0 ma-0 mb-2">7</h1>

          <p
            data-w-id="2a2b3181-a3c5-152c-2582-9c4aa29bf970"
            style="opacity: 1"
            class="paragraph-6"
          >
            Sistema de Noticias de la Región como por ejemplo el Twitter del
            GORE.
          </p>
        </div>
      </div>
      <!-- -->
      <div class="feature-wrap" id="alertas">
        <div
          data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b7e"
          style="
            -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            opacity: 1;
          "
          class="feature-content"
        >
          <h2
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b81"
            style="opacity: 1"
            class="black-text"
          >
            <strong>Alerta de Seguridad</strong>
          </h2>
          <p
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b83"
            style="opacity: 1"
            class="paragraph-8"
          >
            <strong>Región Conectada</strong> enviará notificaciones a todas las
            personas que pertenezcan a tu red de seguridad y a los operadores
            del panel en tu cuadrante. Esta notificación se envía con la
            ubicación desde donde se generó la alerta. Cualquier usuario de tu
            red o algún operador del panel de seguridad pueden intentar
            establecer una videollamada con el usuario que generó la Alerta o
            Contactarlo por una llamada telefónica obteniendo de manera
            inmediata una comunicación con este usuario.
          </p>
          <br />
          <h2 style="opacity: 1" class="black-text">
            <strong
              >"Todas las Alertas generadas serán grabadas en nuestros
              servidores."</strong
            >
          </h2>
          <br />
        </div>
        <div
          data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b85"
          style="opacity: 1"
          class="feature-image"
        >
          <img
            src="@/assets/images/BOTONALERTASEGURIDAD.png"
            width="341"
            style="opacity: 1"
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b86"
            sizes="(max-width: 479px) 90vw, (max-width: 991px) 340.99261474609375px, 34vw"
            alt=""
            class="image-2"
          />
        </div>
      </div>
      <!-- -->
      <div class="feature-wrap reverse">
        <div
          data-w-id="cafe0045-d304-79d9-8f68-af3adaed06e9"
          style="
            -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            opacity: 1;
          "
          class="feature-content"
        >
          <h2 class="black-text">
            <strong
              data-w-id="4239225a-2078-e0e4-bec5-095de772ed0f"
              style="opacity: 1"
              class="bold-text-3"
              >Sistema de Call Center de Video Conferencia “Click2Call”</strong
            >
          </h2>
          <p
            data-w-id="45835889-a334-efa8-f1f2-05d3ba28c07a"
            style="opacity: 1"
            class="paragraph-7"
          >
            A través del Sistema o Call Center de Atención por Video Conferencia
            o Video Llamada de nombre <strong>“Click2Call”</strong> los
            ciudadanos que se encuentren utilizando la Aplicación
            <strong>“Región Conectada”</strong> podrán establecer una
            comunicación por video llamada con el Call Center de Video
            Conferencia.<br /><br />Los usuarios de la Aplicación Móvil podrán
            contactar a los operadores a través del Módulo de Atención, en donde
            tendrán dos alternativas de contacto que a continuación se
            describen:<br /><br />‍<strong>Sistema de Video Llamada:</strong>
            Esta opción permite al ciudadano comunicarse cara a cara con el
            operador, ya sea utilizando su dispositivo móvil, Computador
            Personal o Tablet. <br /><br />‍<strong
              >Sistema de Video Mail:</strong
            >
            Esta opción permite que el ciudadano grabe un video y lo envié por
            email al operador. Este email podrá ser contestado cuando el
            operador así lo determine. <br />‍
          </p>
        </div>
        <div
          data-w-id="cafe0045-d304-79d9-8f68-af3adaed06f2"
          style="opacity: 1"
          class="feature-image"
        >
          <img
            src="@/assets/images/Phone-2.png"
            width="386"
            style="opacity: 1"
            data-w-id="cafe0045-d304-79d9-8f68-af3adaed06f3"
            srcset="
              @/assets/images/Phone-2-p-500.png 500w,
              @/assets/images/Phone-2.png       620w
            "
            sizes="(max-width: 479px) 90vw, (max-width: 991px) 385.99261474609375px, 39vw"
            alt=""
            class="image-2"
          />
        </div>
      </div>
      <div class="feature-wrap" id="denuncias">
        <div
          data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b7e"
          style="
            -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            opacity: 1;
          "
          class="feature-content"
        >
          <h2
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b81"
            style="opacity: 1"
            class="black-text"
          >
            <strong>Sistema de Denuncias de la Plataforma</strong>
          </h2>
          <p
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b83"
            style="opacity: 1"
            class="paragraph-8"
          >
            <strong
              >Sistema de Denuncias de la Plataforma “Región Conectada”. </strong
            >Esta alternativa permite a cada ciudadano, generar denuncias de
            Seguridad Pública ya sea de delitos o incivilidades grabadas las
            cuales serán enviadas en forma inmediata al Panel WEB del Sistema y
            que se encontrara en dependencias de la Fundación. Ante cualquier
            denuncia recibida en el Panel WEB, el funcionario que la recepciona
            podrá comunicarse en forma inmediata vía video conferencia con la
            persona que generó la denuncia.
          </p>
          <br />
          <h2 style="opacity: 1" class="black-text">
            <strong>"Contamos con un Panel WEB de Comunicación Total."</strong>
          </h2>
          <br />
          <p
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b83"
            style="opacity: 1"
            class="paragraph-8"
          >
            <strong>Pantalla de Denuncias: </strong>En esta pantalla se
            seleccionará el tipo de denuncia que se desea generar.
          </p>
        </div>
        <div
          data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b85"
          style="opacity: 1"
          class="feature-image"
        >
          <img
            src="@/assets/images/incivilidades.png"
            width="241"
            style="opacity: 1"
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b86"
            srcset="
              @/assets/images/incivilidades.png 500w,
              @/assets/images/incivilidades.jpg 563w
            "
            sizes="(max-width: 479px) 90vw, (max-width: 991px) 340.99261474609375px, 34vw"
            alt=""
            class="image-2"
          />
        </div>
      </div>
      <div id="how-to-use" class="feature-wrap">
        <div class="feature-image _2">
          <img
            src="@/assets/images/Phone-4.png"
            width="519"
            style="opacity: 1"
            data-w-id="0585a81c-ee42-8b56-bc8e-bd5829463221"
            srcset="
              @/assets/images/Phone-4-p-500.png 500w,
              @/assets/images/Phone-4.png       602w
            "
            sizes="(max-width: 479px) 239.99998474121094px, (max-width: 767px) 349.9999694824219px, 20vw"
            alt=""
            class="image-2"
          />
        </div>
        <div
          data-w-id="0585a81c-ee42-8b56-bc8e-bd582946320a"
          style="
            -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            opacity: 1;
          "
          class="feature-content"
        >
          <h2 class="black-text">
            <strong
              data-w-id="32564d77-ebe3-ad31-61ee-f1e91e7e3260"
              style="opacity: 1"
              class="bold-text-2"
              >Pantalla de Generación de una Denuncias</strong
            >
          </h2>
          <p
            data-w-id="6be42438-4e11-0d64-60b4-7af370562410"
            style="opacity: 1"
            class="paragraph-9"
          >
            En esta pantalla de la Aplicación Móvil, el smartphone comenzará a
            grabar la denuncia utilizando la cámara externa del propio móvil del
            ciudadano. Por ejemplo, para este caso puede ser la de un automóvil
            robado.
          </p>
        </div>
      </div>
      <div id="camaras" class="feature-wrap">
        <div
          data-w-id="0585a81c-ee42-8b56-bc8e-bd582946320a"
          style="
            -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            opacity: 1;
          "
          class="feature-content"
        >
          <h2 class="black-text">
            <strong
              data-w-id="32564d77-ebe3-ad31-61ee-f1e91e7e3260"
              style="opacity: 1"
              class="bold-text-2"
              >Cámara WIFI de visualización través de la Aplicación “Región
              Conectada”</strong
            >
          </h2>
          <p
            data-w-id="6be42438-4e11-0d64-60b4-7af370562410"
            style="opacity: 1"
            class="paragraph-9"
          >
            La Cámara WIFI la cual puede ser conectada a la Aplicación Móvil
            “Región Conectada”, es complementaria a la Aplicación Móvil “Región
            Conectada”, todo lo cual posibilitará que cada usuario que la tenga
            instalada pueda visualizar la imagen en tiempo real de su hogar,
            comercio, etc, en todo momento a través de la Aplicación Móvil
            “Región Conectada”, incluso con la posibilidad de generar audios
            disuasivos o de comunicación dependiendo del caso.
          </p>

          <p
            data-w-id="6be42438-4e11-0d64-60b4-7af370562410"
            style="opacity: 1"
            class="paragraph-9"
          >
            Estas Cámaras también podrán generar Alertas ante la detección de
            movimientos, por ejemplo cuando el hogar se encuentre sin moradores,
            produciendo en forma inmediata una comunicación con el usuario, el
            cual podrá activar la Alarma, la cual será escuchada tanto por el
            Centro de Asistencia o Call Center, como así también, por la Red de
            Seguridad Personal del propio usuario principal a través del
            Aplicativo Móvil “Región Conectada”, produciéndose en forma
            inmediata la comunicación respectiva.
          </p>
          <p
            data-w-id="6be42438-4e11-0d64-60b4-7af370562410"
            style="opacity: 1"
            class="paragraph-9"
          >
            Independiente de lo anterior y con el objeto de tener pruebas
            grabadas ante actos delictuales para ser presentadas en los
            Tribunales de Justicia, estas Cámaras también tienen la posibilidad
            de ir grabando lo que sucede en el hogar, local, etc, en todo
            momento, considerando que pueden contar con una Tarjeta de Grabación
            TF que se le inserta a la propia Cámara y que posibilita la acción
            anteriormente citada.
          </p>
        </div>
        <div class="feature-image_2">
          <img
            src="@/assets/images/composicion.png"
            width="519"
            style="opacity: 1"
            data-w-id="0585a81c-ee42-8b56-bc8e-bd5829463221"
            srcset="
              @/assets/images/composicion.png 500w,
              @/assets/images/composicion.png 602w
            "
            sizes="(max-width: 479px) 239.99998474121094px, (max-width: 767px) 349.9999694824219px, 20vw"
            alt=""
            class="image-2"
          />
        </div>
      </div>
      <div id="red" class="feature-wrap">
        <div class="feature-image_2">
          <img
            src="@/assets/images/composicion2.png"
            width="519"
            style="opacity: 1"
            srcset="
              @/assets/images/composicion2.png 500w,
              @/assets/images/composicion2.png 602w
            "
            sizes="(max-width: 479px) 239.99998474121094px, (max-width: 767px) 349.9999694824219px, 20vw"
            alt=""
            class="image-2"
          />
        </div>
        <div
          data-w-id="0585a81c-ee42-8b56-bc8e-bd582946320a"
          style="
            -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            opacity: 1;
          "
          class="feature-content"
        >
          <h2 class="black-text">
            <strong
              data-w-id="32564d77-ebe3-ad31-61ee-f1e91e7e3260"
              style="opacity: 1"
              class="bold-text-2"
              >Red de Seguridad Personal</strong
            >
          </h2>
          <p
            data-w-id="6be42438-4e11-0d64-60b4-7af370562410"
            style="opacity: 1"
            class="paragraph-9"
          >
            En forma adicional y complementaria a la Comunicación con la Central
            de Atención, como este Sistema contempla la generación de Redes de
            Seguridad para el Usuario de al menos 5 familiares, vecinos, etc,
            estos también tendrán la posibilidad de descargar la Aplicación
            Móvil “Región Conectada” a sus smartphone y así ante cualquier
            emergencia que tenga el usuario principal, podrán comunicarse con
            éste todas o parte de las personas que pertenecen a su Red en forma
            simultánea por video conferencia, en una modalidad de Multipunto de
            Comunicación.
          </p>

          <p
            data-w-id="6be42438-4e11-0d64-60b4-7af370562410"
            style="opacity: 1"
            class="paragraph-9"
          >
            Esta Red de Seguridad Personal, actúa incluso como el primer
            elemento de ayuda y auxilio ante cualquier Emergencia que tenga el
            Usuario, considerando que al generar éste una Alarma personal vía el
            Aplicativo, los primeros en comunicarse en igualdad de condiciones
            con el personal del Centro de Atención o Call Center, serán las
            personas pertenecientes a su Red Personal de Seguridad.
          </p>
        </div>
      </div>
      <br />
      <br />
      <!--div class="feature-wrap icons _3">
        <div class="feature-content icons"><img src="@/assets/images/Denuncias-2.png" width="80" style="opacity:1"
                                                data-w-id="e2d8ef87-6ba6-5f12-72b7-b2cb0ddeae80" alt=""
                                                class="feature-icon">
          <p data-w-id="e2d8ef87-6ba6-5f12-72b7-b2cb0ddeae81" style="opacity:1" class="paragraph-3 just"><strong>Denuncias
            Generadas por el Ciudadano: </strong>En esta pantalla se podrá encontrar un detalle de todas las denuncias
            que ha generado el ciudadano.</p>
        </div>
        <div class="div-block-13"></div>
        <div class="feature-content icons"><img src="@/assets/images/Denuncias-1.png" width="80" style="opacity:1"
                                                data-w-id="e2d8ef87-6ba6-5f12-72b7-b2cb0ddeae85" alt=""
                                                class="feature-icon">
          <p data-w-id="e2d8ef87-6ba6-5f12-72b7-b2cb0ddeae86" style="opacity:1" class="paragraph-4 just"><strong>Detalle de
            la Denuncia: </strong>En esta pantalla se podrá encontrar el detalle de la denuncia, con la fecha, hora y
            ubicación en el plano.</p>
        </div>
        <div class="div-block-13"></div>
        <div class="feature-content icons"><img src="@/assets/images/Denuncias-3.png" width="74" style="opacity:1"
                                                data-w-id="e2d8ef87-6ba6-5f12-72b7-b2cb0ddeae8a" alt=""
                                                class="feature-icon">
          <p data-w-id="e2d8ef87-6ba6-5f12-72b7-b2cb0ddeae8b" style="opacity:1" class="paragraph-5 just"><strong>Noticias
            Regionales tipo Twitter: </strong>Esta alternativa permite a cada ciudadano leer online lo que la Región va
            publicando.</p>
        </div>
      </div-->
    </div>
    <div id="Panel-web" class="section wide img wf-section">
      <div
        data-w-id="b126983d-9126-fc06-c81d-1318685e2add"
        style="
          -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          opacity: 1;
        "
        class="wrap"
      >
        <div class="title">
          <strong class="bold-text" style="color: white !important"
            >Panel WEB de “Región Conectada”</strong
          >
        </div>
        <h2 class="heading-6">
          En el Panel WEB de <strong>“Región Conectada”</strong> se registrarán
          las denuncias que han ido ingresando a través de la Aplicación Móvil,
          este panel cuenta con mapas de calor, detalle de las denuncias
          realizadas, atención de las denuncias en tiempo real por un operador
          de la Fundación.
        </h2>
      </div>
      <div class="wrapper">
        <img
          src="@/assets/images/Lapto-2.png"
          style="opacity: 1"
          data-w-id="c386af35-0586-d8e3-a5c8-af4cd2c6312e"
          sizes="(max-width: 479px) 319.9999694824219px, (max-width: 767px) 549.9999389648438px, (max-width: 991px) 649.9999389648438px, 94vw"
          srcset="
            @/assets/images/Lapto-2-p-500.png   500w,
            @/assets/images/Lapto-2-p-800.png   800w,
            @/assets/images/Lapto-2-p-1080.png 1080w,
            @/assets/images/Lapto-2-p-1600.png 1600w,
            @/assets/images/Lapto-2.png        1704w
          "
          alt=""
          class="image-5"
        />
      </div>
    </div>
    <div id="download" class="section wide purple wf-section">
      <div
        data-w-id="376e2a1f-ec70-0dab-11ea-b1863101e37b"
        style="
          -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
            rotateY(0) rotateZ(0) skew(0, 0);
          opacity: 1;
        "
        class="wrap"
      >
        <h2>¿Estás listo para comenzar?</h2>
        <div class="button-wrapper">
          <!-- <a href="#" class="button w-button">Descargar App</a> -->

          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.naturalphone.regionconectada"
            ><img class="googleplay" src="@/assets/googleplay.png" alt=""
          /></a>
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/regi%C3%B3n-conectada/id6449751083"
            ><img class="appstore" src="@/assets/appstore.svg" alt=""
          /></a>
        </div>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCZxQmE8InwzZNkcXPZKaJ-g"
          ><img
            style="
              border-radius: 25px;
              display: block;
              width: 200px;
              margin: 0 auto;
              margin-bottom: 12px;
            "
            src="@/assets/images/regionyoutube.jpeg"
            alt=""
        /></a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCxyIB_-nR9MO0uh-h_7i9NQ"
          ><img
            style="
              margin-top: 12px;
              border-radius: 25px;
              display: block;
              width: 200px;
              margin: 0 auto;
            "
            src="@/assets/images/click2callyoutube.png"
            alt=""
        /></a>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getPaymentValue,
  startPaymentPlan,
  getOrderStatusByToken,
} from "@/helpers/api/planes";

import "../../assets/js/webflow.js";

export default {
  name: "Home",
  components: {},
  data: () => ({
    toPayPlan: {
      plan_id: 1,
      months_paid: 6,
    },
    loading: false,
    requestedStatus: false,
    playing: false,
    video: false,
  }),
  mounted() {
    if (!this.requestedStatus && this.$route.query && this.$route.query.token) {
      this.getOrderStatus(this.$route.query.token);
    }
  },
  methods: {
    async startPayment() {
      const planPayment = await startPaymentPlan(this.toPayPlan);

      if (planPayment.code === 200) {
        if (
          planPayment.flowData &&
          planPayment.flowData.url &&
          planPayment.flowData.token
        ) {
          const payUrl =
            planPayment.flowData.url + "?token=" + planPayment.flowData.token;
          window.location.href = payUrl;
        }
      }
    },
    async getOrderStatus(order_token) {
      this.requestedStatus = true;

      this.loading = true;
      const status = await getOrderStatusByToken({ order_token });
      if (status.code === 200 && status.orderStatus) {
        console.log(status.orderStatus);
        const data = {
          flowOrder: status.orderStatus.flowOrder,
          amount: status.orderStatus.amount,
          status: status.orderStatus.status,
          forma: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.media
            : "",
          fecha: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.date
            : "",
        };
        this.$store.commit("setModalsStates", {
          paymentStatus: { open: true, data },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/region-conectada.webflow.css";

.number-h1 {
  font-size: 5rem;
}

.googleplay {
  width: 200px;
  height: 80px;
}
.appstore {
  width: 170px;
}
.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.container-descarga {
  display: flex;
  gap: 12px;
}
.container-descargabtns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

@media (min-width: 768px) {
  .container-descargabtns {
    flex-wrap: nowrap;
  }
}

.heroConcourseWrapper {
  cursor: pointer;
  height: 136px;
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffaf00;
  border-radius: 25px;
  top: 190px;
  right: 150px;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 13px 0px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 13px 0px rgba(255, 255, 255, 1);
  z-index: 123;
  &__Img {
    width: 80px;
  }
  &__Text {
  }
}

@media (min-width: 1500px) {
  .heroConcourseWrapper {
    right: 300px;
    width: 300px;
    &__Text {
      font-size: 20px;
    }
  }
}
@media (max-width: 468px) {
  .heroConcourseWrapper {
    width: 140px;
    height: 150px;
    &__Img {
      width: 60px;
    }
    &__Text {
      text-align: center;
    }
  }
}

.apple {
  display: flex;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 468px) {
  .apple {
    display: flex;
    width: 90%;
    margin: 0 auto;
  }
}


  .hero-title {
    margin-top: 0;
  }
@media (min-width: 450px) {
  .hero-title {
    margin-top: 80px;
  }
}

</style>
