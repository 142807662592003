import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta'
import router from './router'
// import VueGtag from "vue-gtag"

Vue.use(VueMeta)
Vue.config.productionTip = false

// Vue.use(VueGtag, {
//   // config: { id: "G-45S9M4LF35" }
// }, router)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
