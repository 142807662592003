<template>
<div>
    <section v-if="!loader" class="Networks">
    <v-tabs v-model="tab" style="    align-items: center;">
        <v-tab>Mi Red</v-tab>
        <v-tab>Redes Externas</v-tab>
        <div v-if="tab == 0" style="display:flex; align-items:center;margin-left:auto;">
        <v-btn @click="dialogAdd = true" color="primary" class="ml-auto ma-5 mb-5">Añadir</v-btn>
        </div>
         <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
            <v-data-table
            :headers="headersMyNetwork"
            :items="my_network"
            :items-per-page="5"
            class="elevation-1">
            </v-data-table>
        </v-card>
        </v-tab-item>

        <v-tab-item>
        <v-card flat>
            <v-data-table
            :headers="headersExternalNetworks"
            :items="external_networks"
            :items-per-page="5"
            class="elevation-1">
            <template v-slot:item.actions="{ item }">
                <div v-if="item.status != 1">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small class="Cards__Card__Delete" v-bind="attrs" v-on="on">
                        <v-icon @click="setCurrentNetwork(item, 'delete')">
                          mdi-delete
                      </v-icon>
                  </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
                </div>

                <div v-else style="display: flex; gap: 14px;"> 

                <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small class="Cards__Card__Delete" v-bind="attrs" v-on="on">
                  <v-icon color="success" @click="setCurrentNetwork(item, 'confirm')">
                    mdi-check-bold
                  </v-icon>
                  </v-btn>
              </template>
              <span>Confirmar</span>
            </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small class="Cards__Card__Delete" v-bind="attrs" v-on="on">
            <v-icon color="error" @click="setCurrentNetwork(item, 'reject')">
                mdi-close-thick
            </v-icon>
                  </v-btn>
              </template>
              <span>Rechazar</span>
            </v-tooltip>
                </div>
        </template>
        </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
    </section>

    <section v-else style="display:flex; justify-content:center;">
        <div class="loader"></div>
    </section>


        <v-dialog v-model="dialogAdd" width="500">
          <v-card>
        <v-card-title class="text-h5 success lighten-2">
          Enviar Invitación
        </v-card-title>
        <v-card-text>
            <v-form ref="formInvitation" v-model="valid" lazy-validation>
        <v-text-field v-model="guest_email" :rules="isEmailValid" @change="(email) => {isEmail(email)}" required label="Email" class="mt-5"></v-text-field>

        <v-btn @click="AddToMyNetwork" color="success">Enviar</v-btn>
            </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialogAdd = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

        <!-- Dialog Añadir a tu Red -->
        <v-dialog v-model="dialogAdd" width="500">
          <v-card>
        <v-card-title class="text-h5 success lighten-2">
          Enviar Invitación
        </v-card-title>
        <v-card-text>
            <v-form ref="formInvitation" v-model="valid" lazy-validation>
        <v-text-field v-model="guest_email" :rules="isEmailValid" @change="(email) => {isEmail(email)}" required label="Email" class="mt-5"></v-text-field>

        <v-btn @click="AddToMyNetwork" color="success">Enviar</v-btn>
            </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogAdd = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

        <!-- Dialog Salir de la red -->
            <v-dialog v-model="dialogQuit" width="500">
          <v-card>
        <v-card-title class="text-h5 error lighten-2">
          Salir de Red de Seguridad
        </v-card-title>
        <v-card-text style="margin-top:20px;">
        <p>
        Salir de la Red de Seguridad de {{currentNetwork.name}}
        </p>
        <v-btn @click="quitNetwork" color="error">Salir</v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogQuit = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

        <!-- Dialog Confirmar o rechazar invitación a red -->
            <v-dialog v-model="dialogConfirmOrReject" width="550">
          <v-card>
        <v-card-title :class="confirmOrReject == 'reject' ? 'error' : 'success'"  class="text-h5 lighten-2">
          {{confirmOrReject == 'confirm' ? 'Aceptar invitación a Red de Seguridad' : 'Rechazar invitación a Red de Seguridad de '}}
        </v-card-title>
        <v-card-text style="margin-top:20px;">
        <p>
            {{confirmOrReject == 'confirm' ? 'Aceptar invitación a Red de Seguridad de ' + currentNetwork.name : 'Rechazar invitación a Red de Seguridad de ' + currentNetwork.name}}
        </p>
        <v-btn @click="confirmOrReject == 'confirm' ? confirmNetwork() : rejectNetwork()" :color="confirmOrReject == 'reject' ? 'error' : 'success'">{{confirmOrReject == 'confirm' ? 'Aceptar' : 'Rechazar'}}</v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogQuit = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-alert
    v-if="alert"
    :color="success = true ? 'green' : 'red'"
    :type="success = true ? 'success' : 'error'"
    style="display:flex; position: absolute;top: 20px;right: 5%;"
    transition="slide-y-transition"
    >{{message}}</v-alert>
    </div>
</template>

<script>
import { getMyNetwork, addUserToNetwork, quitSecurityNetwork, acceptRejectInvitation } from '../../../helpers/api/sosdenuncias/sosdenuncias'

export default {
    data () {
        return {
            success: false,
            alert: false,
            message: '',
            dialogAdd: false,
            dialogQuit: false,
            dialogConfirmOrReject: false,
            confirmOrReject: null,
            my_network: [],
            external_networks: [],
            tab: null,
            headersMyNetwork: [
                { text: 'Invitado', value: 'name' },
                { text: 'Correo electrónico', value: 'email' },
                { text: 'Acciones', value: 'actions' },
            ],
            headersExternalNetworks: [
                { text: 'Propietario', value: 'name' },
                { text: 'Correo electrónico', value: 'email' },
                { text: 'Acciones', value: 'actions' },
            ],
            loader: true,
            guest_email: '',
            valid: false,
            isEmailValid: [
        //eslint-disable-next-line
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Formato incorrecto'
      ],
        required: [
        v => !!v || 'Este campo es obligatorio'
      ],
      currentNetwork: {}
        }
    },
    methods: {
        getNetwork() {
            getMyNetwork().then(res => {
                this.loader = false
                if (res.my_network.length) {
                    this.my_network = res.my_network.map(network => {
                        return {
                        name: network.guest.profile.name + ' ' + network.guest.profile.lastname,
                        email: network.guest.email,
                        id: network.id,
                        status: network.status
                    }
                })
            }
                if (res.external_networks.length) {
                this.external_networks = res.external_networks.map(network => {
                    return {
                        name: network.owner.profile.name + ' ' + network.owner.profile.lastname,
                        email: network.owner.email,
                        id: network.id,
                        status: network.status
                    }
                })
            }   
        })
        },
        AddToMyNetwork() {
            if(this.$refs.formInvitation.validate()){
            addUserToNetwork({guest_email: this.guest_email}).then(res => {
                this.getNetwork();
                this.dialogAdd = false;
                this.guest_email = ''
                this.message = 'Invitación enviada'
                this.alert = true;
                this.success = true;
                setTimeout(() => {
                this.alert = false
            }, 3000);
            }).catch(e => {
            this.message = res.message || 'Error'
            this.alert = true;
            this.success = false;
            })
            }
        },
        setCurrentNetwork(network, dialog) {
            this.currentNetwork = network
            if (dialog == 'delete') {
                this.dialogQuit = true;
            } else {
                this.dialogConfirmOrReject = true
                this.confirmOrReject = dialog
            }
        },
        quitNetwork() {
            quitSecurityNetwork({invitation_id: this.currentNetwork.id}).then(res => {
                this.getNetwork();
                this.dialogQuit = false;
                this.currentNetwork = {}
                this.message = res.message
                this.alert = true;
                this.success = true;
                setTimeout(() => {
                this.alert = false
            }, 3000);
            }).catch(e => {
            this.message = res.message || 'Error'
            this.alert = true;
            this.success = false;
            })
        },
        confirmNetwork() {
            acceptRejectInvitation({invitation_id: this.currentNetwork.id, status: 2}).then(res => {
                this.dialogConfirmOrReject = false;
                this.message = 'Invitación Confirmada'
                this.alert = true;
                this.success = true;
                setTimeout(() => {
                this.alert = false
            }, 3000);
                this.getNetwork()
            }).catch(e => {
            this.message = res.message || 'Error'
            this.alert = true;
            this.success = false;
            })
        },
        rejectNetwork() {
            acceptRejectInvitation({invitation_id: this.currentNetwork.id, status: 3}).then(res => {
                this.dialogConfirmOrReject = false;
                this.message = 'Invitación Rechazada'
                this.alert = true;
                this.success = true;
                setTimeout(() => {
                this.alert = false
            }, 3000);
                this.getNetwork()
            }).catch(e => {
                this.message = res.message || 'Error'
                this.alert = true;
                this.success = false;
            })
        },
        isEmail(email){
        //eslint-disable-next-line
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    },
    },
    mounted() {
        this.getNetwork();
    }
}
</script>

<style lang="scss" scoped>
.Networks {
    border-radius: 15px;
    padding: 20px;
    background-color: #F7F1F1;
}

    .loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
    33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
    66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
    100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}
</style>